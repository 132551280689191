import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Descriptions, Divider, Form, Input, Row, Select, Spin, Tag, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutNav } from 'components/layout.component';
import { LayoutTabMember, MemberLevelSelect, PopupMemberLoginHistory, ViewMemberMemoList } from 'components/member.component';
import { ENABLE, MEMBER_FREEZE, MEMBER_STATE } from 'constants/state';
import { getYear } from 'date-fns';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get } from 'services';
import * as common from 'utils/common';
import { timeS2L } from 'utils/common';

// 會員列表: 基本資料

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const navigate = useNavigate();
  const { id, platformId , account, agId }: any = useParams();
  const [form] = useForm();
  const [isOpenLoginHistory, setIsOpenLoginHistory] = useState(false);

  // 會員標籤列表
  const { data: tagList, isValidating: tagValidating } = $get({
    url: `admin/member/sites/${platformId}/member/tags`,
    params: { status: ENABLE.enabled },
    allow: !!platformId
  });

  const { data: AllAgentList, isValidating: loadingAgentList } = $get({
    url: `admin/member/sites/${platformId}/members/${id}/settle/cycle/agents`,
    allow: !!id && !!platformId
  });
  const { data: member, isValidating } = $get({
    url: `admin/member/sites/${platformId}/members/${id}/data`,
    allow: !!id && !!platformId
  });

  // 會員群組列表
  const { data: MemberGroupNameList } = $get({
    url: `admin/member/sites/${platformId}/member/groups`,
    params: { page: 1, rows: 9999 }
  });

  useEffect(() => {
    if (member) {
      const Tags = JSON.parse(member.Data.TagsId);
      const { Account, Name, IDNumber, PhonePrefix, PhoneNumber, Email, Birth, IsLockLevel, LevelId, AGId, IsFreeze, Status, RefCode, CreateDate, LastLogInDate, VerifiedDate, RegisterIP, NoCode, MemberGroupId } = member.Data;
      form.setFieldsValue({
        Account,
        Name,
        IDNumber,
        PhonePrefix: PhonePrefix ? PhonePrefix.replace('+', '') : '',
        PhoneNumber,
        Email: Email || '',
        birthYear: Birth ? Birth.split('-')[0] : '',
        birthMonth: Birth ? Birth.split('-')[1] : '',
        birthDate: Birth ? Birth.split('-')[2] : '',
        IsLockLevel: !!IsLockLevel,
        LevelId,
        AGId,
        IsFreeze: IsFreeze ? MEMBER_FREEZE.frozen : MEMBER_FREEZE.normal,
        Status,
        RefCode,
        CreateDate: timeS2L(CreateDate),
        LastLogInDate: timeS2L(LastLogInDate),
        VerifiedDate: timeS2L(VerifiedDate),
        RegisterIP,
        NoCode,
        TagsId: Tags.map((item: number) => `${item}`),
        MemberGroupId
      });
    }
  }, [member]);

  return (
    <div id="container">
      <Content>
        <LayoutTabMember activeKey="1" id={id} platformId={platformId} account={account} agId={agId}/>
        {/* 基本資料 */}
        <Spin spinning={isValidating}>
          <Form form={form}>
            <Row align="middle" gutter={[12, 12]}>
              <Col span={24}>
                <Row justify="end" gutter={[12, 12]}>
                  <Col><Button onClick={() => navigate('/member')}>{i18n.t('close')}</Button></Col>
                </Row>
              </Col>
              <Col span={24}>
                <Descriptions
                  labelStyle={{ fontSize: 12, fontWeight: 700, width: 200 }}
                  contentStyle={{ width: 450 }}
                  size="small"
                  bordered
                >
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('memberAccount')}</>}>
                    <Form.Item name="Account">
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('realName')}</>}>
                    <Form.Item name="Name">
                      <Input disabled placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('idNum')}>
                    <Form.Item name="IDNumber">
                      <Input disabled placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={
                    <><span className="require">*</span>{i18n.t('countryCode')}-{i18n.t('phoneNumber')}</>
                  }>
                    <Row>
                      <Col span={8}>
                        <Form.Item name="PhonePrefix">
                          <Input autoComplete="off" disabled placeholder={`${i18n.t('enterCountryCode')}`} addonBefore={'+'} />
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item name="PhoneNumber">
                          <Input disabled placeholder={`${i18n.t('inputMobile')}`} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('email')}</>}>
                    <Form.Item name="Email">
                      <Input disabled placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('birthday')}</>}>
                    <Row>
                      <Col span={8}>
                        <Form.Item name="birthYear">
                          <Select
                            className="w-full"
                            disabled
                            placeholder={i18n.t('pleaseSelect')}
                            options={
                              Array.from({ length: 100 }).map((_, i) => {
                                const year = getYear(new Date()) - i;
                                return { value: `${year}`, label: `${year}` }
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="birthMonth">
                          <Select
                            className="w-full"
                            disabled
                            placeholder={i18n.t('pleaseSelect')}
                            options={
                              Array.from({ length: 12 }).map((_, i) => {
                                const month = common.leftPad((i + 1).toString(), 2, '0');
                                return { value: `${month}`, label: `${month}` }
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="birthDate">
                          <Select
                            className="w-full"
                            disabled
                            placeholder={i18n.t('pleaseSelect')}
                            options={
                              Array.from({ length: 0 }).map((_, i) => {
                                const day = common.leftPad((i + 1).toString(), 2, '0');
                                return { value: `${day}`, label: `${day}` }
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={
                    <Row align="middle" gutter={[2, 12]}>
                      <Col>
                        <span className="require">*</span><span>{i18n.t('memberLevel')}</span>
                      </Col>
                      <Form.Item name="IsLockLevel" valuePropName="checked">
                        <Checkbox
                          disabled
                          className="size-10 color-05"
                        >{i18n.t('lockLevel')}
                        </Checkbox>
                      </Form.Item>
                      <span>
                        <Tooltip placement="top" className="size-12" title={i18n.t('notApplicableMemberLevelSetting')}>
                          <QuestionCircleFilled />
                        </Tooltip>
                      </span>

                    </Row>
                  }>
                    <Form.Item name="LevelId">
                      <MemberLevelSelect name="LevelId" form={form} disabled all={false} platformId={platformId} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('agent')}</>}>
                    <Form.Item name="AGId">
                      <Select
                        className="w-full"
                        disabled
                        loading={loadingAgentList}
                        placeholder={`${i18n.t('pleaseSelect')}`}
                        optionFilterProp="children"
                        showSearch
                        filterOption={common.selectFuzzyFilter}
                        options={
                          AllAgentList?.Data.map((item: { Id: number, Account: string }) => (
                            { value: item.Id, label: item.Account }
                          )) || []
                        }
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('status')}</>}>
                    <Form.Item name="IsFreeze">
                      <Select
                        disabled
                        style={{ width: "100%" }}
                        options={common.enumToOptions(MEMBER_FREEZE)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('audit')}</>}>
                    <Form.Item name="Status">
                      <Select
                        disabled
                        style={{ width: "100%" }}
                        options={common.enumToOptions(MEMBER_STATE)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<>{i18n.t('password')}</>}>
                    <Input 
                      placeholder={`4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`} 
                      type="password" disabled value="**********" />
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('promotionCode')}>
                    <Form.Item name="RefCode">
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('identifier')}>
                    <Form.Item name="NoCode">
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('createTime')}>
                    <Form.Item name="CreateDate">
                      <Input placeholder="-" disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('lastLoginTime')}>
                    <Form.Item name="LastLogInDate">
                      <Input placeholder="-" disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('verificationTime')}>
                    <Form.Item name="VerifiedDate" >
                      <Input placeholder="-" disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={
                    <Row justify="space-between" align="middle">
                      <Col>{'IP'}</Col>
                      <Col>
                        <Button className="size-12" type="link" onClick={() => setIsOpenLoginHistory(true)}>{i18n.t('more')}</Button>
                      </Col>
                    </Row>
                  }>
                    <Form.Item name="RegisterIP" >
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* <Descriptions.Item label={i18n.t('nickname')}>
                    <Form.Item name="Nickname">
                      <Input placeholder={`4~12${i18n.t('characters')}`} disabled />
                    </Form.Item>
                  </Descriptions.Item> */}
                  <Descriptions.Item label={i18n.t('memberTag')}>
                    <Form.Item name="TagsId">
                      <Select
                        mode="tags"
                        loading={tagValidating}
                        disabled
                        className="w-full"
                        placeholder={i18n.t('pleaseSelect')}
                        options={
                          tagList?.Data
                            .map((option: any) => ({
                              value: `${option.Id}`,
                              label:
                                <Tag key={option.Id} style={{ color: option.TextColor, background: option.Color }}>
                                  {option.Name}
                                </ Tag>
                            }))
                        }
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span><span>{i18n.t('memberGroup')}</span></>}>
                    <Form.Item name="MemberGroupId" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Select
                        className="w-full"
                        disabled
                        placeholder={i18n.t('pleaseSelect')}
                        options={
                          MemberGroupNameList ?
                            MemberGroupNameList?.Data?.map((item: { Id: number, Name: string }) => (
                              { value: item.Id, label: item.Name }
                            )) : []
                        }
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/*對齊用*/}
                  <Descriptions.Item >{""}</Descriptions.Item>
                  <Descriptions.Item >{""}</Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Form>
        </Spin>

        {$p('memberInformation.getMemberMemoList') && <>
        <Divider />
        <ViewMemberMemoList id={member?.Data?.Id} platformId={platformId} />
        </>}
        <PopupMemberLoginHistory isOpen={isOpenLoginHistory} close={() => setIsOpenLoginHistory(false)} id={member?.Data?.Id} account={member?.Data?.Account} platformId={platformId} />
      </Content >
    </div >
  );
};

export default PageMain;