import { Col, Row, Switch, Table, message, Form, Input, Image, Button, Divider, Empty } from "antd";
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabPlatform, UploadImage } from "components/layout.component";
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from "react";
import { $get, $api } from 'services';
import { useForm } from "antd/lib/form/Form";
import Default from 'assets/image/default-customer-service-system.png';
import Line from 'assets/image/line.png';
import Telegram from 'assets/image/telegram.png';
import Skype from 'assets/image/skype.png';
import Whatsapp from 'assets/image/whatsapp.png';
import Messenger from 'assets/image/messenger.png';
import Instagram from 'assets/image/instagram.png';
import Zalo from 'assets/image/zalo.png';
import Meiqia from 'assets/image/meiqia.png';
import { ENABLE } from "constants/state";
import { UploadFile } from "antd/lib";
import { PlatformLayout } from "../components/advanced.component";
import { useSelector } from "react-redux";
import { RootType } from "../hooks/store.hook";
import mockCustomerServiceSoftware from "services/customer-service-software/sites-1-settings";

const iconMap: Record<string, string> = {
  Default,
  Line,
  Telegram,
  Skype,
  Whatsapp,
  Messenger,
  Instagram,
  Zalo,
  Meiqia
};

// 站台設置 > 客服軟體設定
const PageMain: React.FC = () => {
  const [siteId, setSiteId] = useState(useSelector((state: RootType) => state.Select.siteId) || null);
  const [form] = useForm();
  const [isEditDone, setIsEditDone] = useState(false);
  const [list, setList] = useState<any>([]);
  const [preZaloImg, setPreZaloImg] = useState<UploadFile<any>[]>([]);
  const [zaloImg, setZaloImg] = useState<UploadFile<any>[]>([]);
  // 狀態啟用時則該列連結為必填相關
  const [checkedState, setCheckedState] = useState<any>({});
  const { permissions: $p } = useAccount();
  const { data: customerServiceSoftwareData, isValidating, mutate } = $get({
    url: `admin/customer-service-software/sites/${siteId}/settings`,
    allow: !!siteId,
    mock: mockCustomerServiceSoftware
  });

  useEffect(() => {
    if (customerServiceSoftwareData?.Data) {
      const list = customerServiceSoftwareData?.Data?.map((item: any, index: number) => {
        let icon = '';
        switch (item.Code) {
          case "Default":
            icon = Default;
            break;
          default:
            icon = '';
        }
        return {
          ...item,
          key: item.Id,
          Icon: item.Code === "Default" ? Default: iconMap[item.Code],
          statusId: index
        };
      });

      const preZaloImg = JSON.parse(list.find((item: any) => item.Code === 'Zalo')?.Data || '{}')?.Qrcode
      setPreZaloImg(preZaloImg)
      setList(list)
    }

  }, [customerServiceSoftwareData]);

  useEffect(() => {
    init();
  }, [list]);

  const init = (validate = false) => {
    if (list) {
      const field: any = {};
      const initialCheckedState: any = {};

      list.map((item: any, i: number) => {
        field[`Code-${item.statusId}`] = item.Code;
        if(item.Type === 'Url') field[`Url-${item.statusId}`] = JSON.parse(item?.Data || '{}')?.Url;
        if(item.Type === 'Qrcode') {
          field[`Url-${item.statusId}`] = JSON.parse(item?.Data || '{}')?.QRCode;
          setZaloImg([
            {
              uid: JSON.parse(item?.Data || '{}')?.QRCode,
              name: JSON.parse(item?.Data || '{}')?.QRCode,
              url: JSON.parse(item?.Data || '{}')?.QRCode
            },
          ])
        }
        if(item.Type === 'Secret') field[`Url-${item.statusId}`] = JSON.parse(item?.Data || '{}')?.entId;
        field[`IsEnabled-${item.statusId}`] = item.IsEnabled;
      })

      form.setFieldsValue(field);

      list.forEach((item: any, i: number) => {
        initialCheckedState[i] = item.IsEnabled === ENABLE.enabled;
      });

      setCheckedState(initialCheckedState);
    }
  };

  const save = (formData: any) => {
    const Settings: any = [];
    const allFalse = Object.values(checkedState).every(value => value === false);
    if(allFalse) {
      message.error(i18n.t('atLeastOneMustBeEnabled'))
      return;
    }

    list.map((item: any, i: number) => {
      const record: any = {};
      let data = ''
      switch (item.Type) {
        case 'Url':
          data = 'Url'
          break;
        case 'Qrcode':
          data = 'QRCode'
          break;
        case 'Secret':
          data = 'entId'
          break;
        default:
          break;
      }
      record[`Code`] = item.Code;
      record[`Type`] = item.Type === 'Qrcode' ? 'QRCode' : item.Type;
      if(item.Code === 'Zalo') {
        record[`Data`] = { [data]: zaloImg[0]?.url}
      } else {
        record[`Data`] = { [data]: formData[`Url-${item.statusId}`]}
      }
      record[`IsEnabled`] = formData[`IsEnabled-${item.statusId}`] ? 1 : 0 ;

      Settings.push(record);
    })

    const { IsEnabled: defaultIsEnabled } = Settings.find((item: any) => item.Code === "Default")
    const { IsEnabled: meiqiaIsEnabled } = Settings.find((item: any) => item.Code === "Meiqia")
    // if (defaultIsEnabled === 1 && meiqiaIsEnabled === 1) {
    //   message.error(i18n.t('onlyOneCanBeEnabled'))
    //   return;
    // }

    $api('PATCH', {
      url: `admin/customer-service-software/sites/${siteId}/settings`,
      send: {
        Settings
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        setIsEditDone(false);
        mutate();
      },
    })
  };

  const onCancel = () => {
    init()
    setIsEditDone(false)
  }


    const onChange = (checked: boolean, index: number) => {
      setCheckedState((prev: any) => ({
        ...prev,
        [index]: checked
      }));
    };


  return (
    <div id="container">
      <Content className="ph-2 pt-1">
        <PlatformLayout tabKey={'7'} setSiteIdOutside={setSiteId} />
        {!!siteId ?
        <Form form={form} onFinish={save}>
          <Row align="middle" gutter={[10, 16]}>
            <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
              {
                isEditDone && <>
                  <Button className="mr-1"  onClick={() => onCancel()}>{i18n.t('cancel')}</Button>
                  <Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
                </>
              }
              {
                !isEditDone && $p('customerServiceSoftware.updateCustomerServiceSoftwareSettings') &&
                <Button type="primary" onClick={() => setIsEditDone(!isEditDone)}>{i18n.t('edit')}</Button>
              }
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating}
                size="middle"
                dataSource={list.filter((item: any) => item.Type === 'Url')}
                columns={list.filter((item: any) => item.Type === 'Url') && [
                  {
                    dataIndex: 'Code',
                    title: i18n.t('software'),
                    width: 200,
                    align: 'left',
                    render: (val, record, i) => {
                      let displayText;
                      switch (val) {
                        case "Default":
                          displayText = i18n.t('builtInCustomerServiceSystem');
                          break;
                        default:
                          displayText = val;
                      }
                      return (
                        <>
                          <Form.Item name={`Code-${record.statusId}`}>
                            <Image src={record?.Icon} width={50} height={50} preview={false} />
                            <span className="ml-1">{displayText}</span>
                          </Form.Item>
                        </>
                      );
                    }
                  },
                  {
                    dataIndex: 'Url',
                    title: <><span className="require">*</span>{i18n.t('link')}</>,
                    width: 1000,
                    render: (_, record: any, i) => {
                      return (record.statusId === 0 ? (
                        <span>-</span>
                      ) : (
                        <Form.Item name={`Url-${record.statusId}`}
                          rules={[
                            ...(checkedState[record.statusId] ? [{ required: true, message: `${i18n.t('required')}` }] : [{required: false}])
                          ]}
                        >
                          <Input
                            placeholder={`${i18n.t('inputData')}`}
                            disabled={!isEditDone}
                          />
                        </Form.Item>
                      )
                    )}
                  },
                  {
                    dataIndex: 'IsEnabled',
                    title: i18n.t('status'),
                    width: 200,
                    align: 'center',
                    render: (val, record, i) =>{
                      return <Form.Item name={`IsEnabled-${record.statusId}`}>
                        <Switch
                          disabled={!isEditDone}
                          onChange={(checked) => onChange(checked, record.statusId)}/>
                      </Form.Item>}
                  },
                ]}
                pagination={false}
              />
              <Divider/>
              <Table
                loading={isValidating}
                size="middle"
                dataSource={list.filter((item: any) => item.Type === 'Qrcode')}
                columns={list.filter((item: any) => item.Type === 'Qrcode') && [
                  {
                    dataIndex: 'Code',
                    title: i18n.t('software'),
                    width: 200,
                    align: 'left',
                    render: (val, record, i) => {
                      return (
                        <>
                          <Form.Item name={`Code-${record.statusId}`}>
                            <Image src={record?.Icon} width={50} height={50} preview={false} />
                            <span className="ml-1">{val}</span>
                          </Form.Item>
                        </>
                      );
                    }
                  },
                  {
                    dataIndex: 'Url',
                    title: <><span className="require">*</span>Qr Code</>,
                    width: 1000,
                    render: (_, record: any, i) => {
                      return (record.statusId === 0 ? (
                          <span>-</span>
                        ) : (
                          <div style={{maxWidth: '1000px', width: '100%'}}>
                            <Form.Item name={`Url-${record.statusId}`} rules={[...(checkedState[record.statusId] ? [{ required: true, message: `${i18n.t('required')}` }] : [{required: false}])]}>
                              <UploadImage
                                disabled={!isEditDone}
                                name={`Url-${record.statusId}`}
                                url={'admin/customer-service-software/image'}
                                preUrl={preZaloImg}
                                imageData={zaloImg}
                                setImageData={setZaloImg}
                                s={'1MB'}
                                accept=".jpg,.jpeg,.png"
                                form={form}
                                otherFunction={mutate}
                              />
                            </Form.Item>
                          </div>
                        )
                      )}
                  },
                  {
                    dataIndex: 'IsEnabled',
                    title: i18n.t('status'),
                    width: 200,
                    align: 'center',
                    render: (val, record, i) =>{
                      return <Form.Item name={`IsEnabled-${record.statusId}`}>
                        <Switch
                          disabled={!isEditDone}
                          onChange={(checked) => onChange(checked, record.statusId)}/>
                      </Form.Item>}
                  },
                ]}
                pagination={false}
              />
              <Divider/>
              <Table
                loading={isValidating}
                size="middle"
                dataSource={list.filter((item: any) => item.Type === 'Secret')}
                columns={list.filter((item: any) => item.Type === 'Secret') && [
                  {
                    dataIndex: 'Code',
                    title: i18n.t('software'),
                    width: 200,
                    align: 'left',
                    render: (val, record, i) => {
                      return (
                        <>
                          <Form.Item name={`Code-${record.statusId}`}>
                            <Image src={record?.Icon} width={50} height={50} preview={false} />
                            <span className="ml-1">{val}</span>
                          </Form.Item>
                        </>
                      );
                    }
                  },
                  {
                    dataIndex: 'Url',
                    title: <><span className="require">*</span>{i18n.t('customerServiceKey')}</>,
                    width: 1000,
                    render: (_, record: any, i) => {
                      return (record.statusId === 0 ? (
                          <span>-</span>
                        ) : (
                          <Form.Item name={`Url-${record.statusId}`}
                                     rules={[
                                       ...(checkedState[record.statusId] ? [{ required: true, message: `${i18n.t('required')}` }] : [{required: false}])
                                     ]}
                          >
                            <Input
                              placeholder={`${i18n.t('inputData')}`}
                              disabled={!isEditDone}
                            />
                          </Form.Item>
                        )
                      )}
                  },
                  {
                    dataIndex: 'IsEnabled',
                    title: i18n.t('status'),
                    width: 200,
                    align: 'center',
                    render: (val, record, i) =>{
                      return <Form.Item name={`IsEnabled-${record.statusId}`}>
                        <Switch
                          disabled={!isEditDone}
                          onChange={(checked) => onChange(checked, record.statusId)}/>
                      </Form.Item>}
                  },
                ]}
                pagination={false}
              />
            </Col>
          </Row>
        </Form>
        : <Empty />
        }
      </Content>
    </div>
  );
};

export default PageMain;