import i18n from "../i18n";

export const GATEWAY_CODE_WITHDRAW = {
  WITHDRAW_GATE_BANK: "WITHDRAW_GATE_BANK",
  WITHDRAW_GATE_BANK_OFFLINE: "WITHDRAW_GATE_BANK_OFFLINE",
  WITHDRAW_GATE_CRYPTO: "WITHDRAW_GATE_CRYPTO",
  WITHDRAW_GATE_CRYPTO_OFFLINE: "WITHDRAW_GATE_CRYPTO_OFFLINE"
}

export const GATEWAY_CODE_DEPOSIT = {
  DEPOSIT_GATE_BANK: "DEPOSIT_GATE_BANK",
  DEPOSIT_GATE_CVS: "DEPOSIT_GATE_CVS",
  DEPOSIT_GATE_ATM: "DEPOSIT_GATE_ATM",
  DEPOSIT_GATE_BANK_OFFLINE: "DEPOSIT_GATE_BANK_OFFLINE",
  DEPOSIT_GATE_CRYPTO_OFFLINE: "DEPOSIT_GATE_CRYPTO_OFFLINE"
}

export enum GATEWAY {
  WITHDRAW_GATE_BANK = 1,
  WITHDRAW_GATE_CRYPTO = 2
}

export const WITHDRAW_TYPE: any = {
  WITHDRAW_GATE_BANK: i18n.t("thirdParty"),
  WITHDRAW_GATE_BANK_OFFLINE: i18n.t("transfer"),
}

export const WALLET_TRANSLATE: any = {
  DEPOSIT_GATE_BANK_OFFLINE: i18n.t("bankCardDepositing"),
  DEPOSIT_GATE_CRYPTO_OFFLINE: i18n.t("virtualCurrencyDepositing"),
  DEPOSIT_GATE_CVS: i18n.t("storePayment"),
  DEPOSIT_GATE_ATM: i18n.t("atm"),
  DEPOSIT_GATE_QR: i18n.t("qrScanning"),
  DEPOSIT_GATE_DC: i18n.t("directConnection"),
  DEPOSIT_GATE_CC: i18n.t("creditCard"),
  DEPOSIT_GATE_CRYPTO: i18n.t("virtualCurrencyDepositingThird"),
  WITHDRAW_GATE_BANK: i18n.t("bankCard"),
  WITHDRAW_GATE_CRYPTO: i18n.t("virtualCurrency")
}
