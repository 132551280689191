import { Button, Col, DatePicker, DatePickerProps, Form, Input, InputNumber, Row, Select, Spin, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { Content } from "antd/es/layout/layout";
import { LayoutNav } from "components/layout.component";
import { CKEditorComp } from "components/promotion.component";
import { RESPONSE_CODE_CREATE_ANNOUNCEMENT } from "constants/response";
import { ENABLE } from "constants/state";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { $api, $get } from "services";
import { enumToOptions, timeS2LDayjs, unixLast999, verify } from "utils/common";
import dayjs, { Dayjs } from "dayjs";
import { SiteCodeAndName } from "components/site.component";

// 系統公告編輯

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = useForm();
  const [editorContent, setEditorContent] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentDayjs, setCurrentDayjs] = useState(dayjs());
  
  const { data, isValidating } = $get({
    url: `admin/system-announcement/announcements/${id}`,
  })

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        title: data.Data.title,
        isEnabled: data.Data.isEnabled ? ENABLE.enabled : ENABLE.disabled,
        beginTime: timeS2LDayjs(data.Data.beginTime), 
        endTime: timeS2LDayjs(data.Data.endTime),
        sequence: data.Data.sequence,
        siteIds: data.Data.sites.map((s: any) => s.id),
        contents: data.Data.contents
      })
      setEditorContent(data.Data.contents);
    }
  }, [data]);

  const onDateOk: DatePickerProps['onOk'] = (date) => {
    // if (date.get('D') === dayjs().get('D') &&
    //   (date.get('hours') < dayjs().get('hours') || date.get('minutes') < dayjs().get('minutes'))) {
    //   const resetDate = dayjs().set('years', date.year()).set('D', dayjs().get('D')).set('hours', dayjs().get('hours')).set('minutes', dayjs().get('minutes'));
    //   form.setFieldValue('publicationTime', dayjs(resetDate));
    // }
  };

  const onFinish = (formData: any) => {
    const beginTime = formData.beginTime.valueOf();
    const endTime = unixLast999(formData.endTime.valueOf());

    $api('PUT', {
      url: `admin/system-announcement/announcements/${id}`,
      send: {
        siteIds: formData.siteIds,
        title: formData.title,
        isEnabled: !!formData.isEnabled,
        sequence: formData.sequence,
        beginTime,
        endTime,
        contents: editorContent,
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onCancel();
      },
      resCode: RESPONSE_CODE_CREATE_ANNOUNCEMENT
    })
  }

  const onCancel = () => {
    form.resetFields();
    navigate('/advanced/announcement');
  }

  return (
    <div id="container">
      <Content className="ph-2 pt-1">
        <Spin spinning={isValidating}>
          <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
            // sort: 0,
            // status: 1,
          }}>
            <Row gutter={[12, 8]}>
              <Col span={24}>
                <Row align="top" justify="space-between">
                  <Col>
                    <Form.Item name="title" label={i18n.t('title')} rules={[
                      { required: true },
                      { max: 100, message: `${i18n.t('exceedsCharacterLimit', { length: 100 })}` }
                    ]}>
                      <Input className="w-18" placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Row align="middle" gutter={[12, 12]}>
                      <Col>
                        <Button onClick={onCancel}>{i18n.t('cancel')}</Button>
                      </Col>
                      <Col>
                        <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item className="w-18" name="siteIds" label={i18n.t('platform')} rules={[{ required: true }]}>
                  <SiteCodeAndName name="siteIds" form={form} apiUrl={'admin/system-announcement/sites'} mode="multiple" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="sequence" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                  <InputNumber className="w-12" placeholder={`${i18n.t('inputData')}`} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="isEnabled" label={i18n.t('status')} rules={[{ required: true }]} className="w-12">
                  <Select
                    placeholder={i18n.t('pleaseSelect')}
                    options={enumToOptions(ENABLE)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item className="w-12" name="beginTime" label={i18n.t('publicationTime')} rules={[{ required: true }]} >
                  <DatePicker
                    onChange={() => {
                      form.getFieldValue('publicationTime') ? setIsDisabled(false) : setIsDisabled(true);
                      form.setFieldValue('endTime', '');
                    }}
                    disabledDate={(current: Dayjs) => current < currentDayjs.subtract(10, 'second')}
                    showTime={{
                      defaultValue: currentDayjs
                    }}
                    className="w-full"
                    format={"YYYY-MM-DD HH:mm:ss"}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item className="w-12" name="endTime" label={i18n.t('endTime')} rules={[{ required: true }]} >
                  <DatePicker
                    disabledDate={(current: Dayjs) => current < form.getFieldValue('beginTime')}
                    showTime={{
                      defaultValue: dayjs('23:59:59', 'HH:mm:ss'),
                    }}
                    className="w-full"
                    format={"YYYY-MM-DD HH:mm:ss"}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="contents" label={i18n.t('internalContent')} rules={[{ required: true }]}>
                  <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'contents'} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Content>
    </div >
  )
}

export default PageMain;