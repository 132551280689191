const main = {

  headers: {
    Page: 1,
    Rows: 10,
    Total: 22,
    get: function(key: string) {
      return this[key];
    }
  } as any,

  "sites/1/withdraw/info": function() {
    const body: any = {
      "MemberMaxWithdrawPerDay": 999999.444,
      "MemberCompleteWithdrawAmount": 999999,
      "MemberAuditeWithdrawAmount": 999999,
      "MemberStillWithdrawAmount": 999999.4321
    };

    return {
      body,
      headers: main.headers
    };
  },

  "sites/1/balance/info": function() {
    const body: any = {
      "WinLossAmount": -9884,
      "ValidBetAmount": 31000,
      "Balance": 35800426.1551,
      "ShouldValidBetAmount": 1242777.53,
      "TotalDepositAmount": 1700,
      "TotalWithdrawAmount": 15100,
      "TotalBonusAmount": 0,
      "TotalAdjustPlusAmount": 0,
      "TotalAdjustMinusAmount": 0,
      "TotalCommissionAmount": 0
    };

    return {
      body,
      headers: main.headers
    };
  },

  "sites/1/cash-log/logs": function() {
    const body: any = Array.from({ length: 22 }, (_, i) => ({
      "Id": 89608,
      "Code": "1202FFA627FCAC7606A2F4A4F9CF35AD",
      "Role": 0,
      "OwnerId": 81,
      "KeyId": "2002",
      "WalletType": 0,
      "Amount": -6200,
      "BeforeAmount": 35806650.9551,
      "AfterAmount": 35800450.9551,
      "TableId": 25451,
      "Remark": "",
      "CreateDate": "1724212080000"
    }));

    return {
      body,
      headers: main.headers
    };
  }

};

export default main;
