import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { Link, useRouteError } from "react-router-dom";
import i18n from 'i18n';

import PageBetEecord from "pages/bet-record.page";
import PageBusiness from "pages/business.page";
import PageSettlement from "pages/settlement.page";
import PageSettlementSetting from "pages/settlement-setting.page";
import PageSms from "pages/sms.page";
import PageDashboard from "../pages/dashboard";
import React from "react";

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const reportModule = [
  {
    path: '/', ErrorBoundary,
    children: [
      { path: '/report', element: <PageBetEecord/>, Breadcrumb: [
        { title: i18n.t('statisticalReports') },
        { title: i18n.t('bettingReports') }
      ]},
      { path: '/report/summary', element: <PageDashboard/>, Breadcrumb: [
          { title: i18n.t('home') }
        ] },
      { path: '/report/business', element: <PageBusiness/>, Breadcrumb: [
        { title: i18n.t('statisticalReports') },
        { title: i18n.t('operationReports') }
      ]},
      { path: '/report/settlement', element: <PageSettlement/>, Breadcrumb: [
        { title: i18n.t('statisticalReports') },
        { title: i18n.t('settlementReport') }
      ]},
      { path: '/report/settlement/setting', element: <PageSettlementSetting/>, Breadcrumb: [
        { title: i18n.t('statisticalReports') },
        { title: <Link to='/report/settlement'>{i18n.t('settlementReport')}</Link> },
        { title: i18n.t('setting') }
      ]},
      { path: '/report/sms', element: <PageSms/>, Breadcrumb: [
        { title: i18n.t('statisticalReports') },
        { title: i18n.t('smsRecord') }
      ]},
    ],
  },
]