import { message } from "antd";
import { COOKIE } from "constants/cookie";
import { RESPONSE_CODE } from "constants/response";
import i18n from "i18n";
import Cookies from "js-cookie";
import QueryString from 'qs';
import { useEffect, useRef, useState } from "react";
import useSWR from 'swr';

// 是否啟用mock(全站)
const MOCK_ENABLE = 0;

const requestInformation = (data: any) => {
  const query = QueryString.stringify(data, { addQueryPrefix: true });
  const token = Cookies.get(COOKIE.TOKEN);
  const headers: any = {
    'Content-Type': 'application/json'
  }

  if (token) headers.Authorization = `${token}`;

  return {
    query,
    headers,
    token
  }
}

// 回應狀態碼判斷
export const responseProcessing = async (response: any) => {
  const body = await response.text();
  let res: any = {};

  if (response.status === 401) {
    res = { errorCode: 'unauthorized' }
  } 
  else if (body.includes('errorCode') || (
    response.status !== 404 &&
    response.status !== 400
  )) {
    res = body ? JSON.parse(body) : {};
  } 
  else {
    res = { errorCode: 'fail' }
  }

  return res;
}

// 錯誤訊息及處理
const errorAlert = (errorCode: string, resCode: any = {}, showMessage?: boolean) => {

  // 沒有權限
  if (errorCode === 'forbidden') {
    window.location.replace('/#/');
    return;
  }
  else if (errorCode === 'unauthorized') {
    window.location.replace('/#/login/UNAUTHORIZATION');
    Cookies.remove(COOKIE.TOKEN);
    return;
  } 
  else if (errorCode === 'accountIsDisabled' || errorCode === 'accountRoleIsDisabled') {
    if (window.location.hash.includes('#/login')) {
      if (showMessage) message.error(RESPONSE_CODE['ACCOUNT_BLOCKED']);
      return;
    } else {
      window.location.replace('/#/login/ACCOUNT_BLOCKED');
      Cookies.remove(COOKIE.TOKEN);
      return;
    }
  }

  const codeI18n: any = {
    ...RESPONSE_CODE,
    ...resCode
  }

  if (showMessage) message.error(codeI18n[errorCode] || i18n.t('incorrectRequest'));
}

interface GetParameters {
  url: string;
  params?: Record<string, any>;
  allow?: boolean;
  showMessage?: boolean;
  resCode?: Record<string, any>;
  auth?: boolean;
  fail?: (data: any) => void;
  success?: (data: any) => void;
  mock?: () => { body: any, headers: any };
}

interface GetResponse {
  TotalRecord?: any;
  TotalBetAmount?: any;
  Data: any[];
  Page?: string | number | null;
  Rows?: string | number | null;
  Total?: string | number | null;
  Headers: Record<string, any>;
  Timestamp: number;
  TotalValidBetAmount?: any;
  TotalWinLossAmount?: any;
  State?: any;
}

type Swr = {
  data?: any;
  isValidating: boolean;
  mutate: () => void;
}

// 現役get
export const $get = ({
  url = '',
  params = {},
  allow = true,
  showMessage = true,
  resCode = {},
  auth = true,
  fail = (data: any) => {},
  success = (data: any) => {},
  mock
}: GetParameters): Swr => {

  const { query, headers, token } = requestInformation(params);

  return useSWR(allow && ((auth && token) || (!auth)) ? `/${url}${query}` : null, async () => {
    return fetch(`/${url}${query}`, {
      method: 'GET',
      headers
    })
      .then(async response => {
        let res = await responseProcessing(response);
        let headers = response.headers;

        if (MOCK_ENABLE && process.env.NODE_ENV === 'development' && mock) {
          const { body, headers: mockHeaders } = mock();
          res = body;
          headers = mockHeaders;
        }

        // 成功
        if (!res.errorCode && res.name !== 'Error') {
          success(res);
          const result: GetResponse = {
            Data: res,
            Page: headers.get('Page'),
            Rows: headers.get('Rows'),
            Total: headers.get('Total'),
            Headers: headers,
            Timestamp: Date.now()
          }
          return result;

        // 失敗
        } else {
          errorAlert(res.errorCode, resCode, showMessage);
          fail(res);
          const result: GetResponse = {
            Data: [],
            Page: 1,
            Rows: 30,
            Total: 0,
            Headers: {
              get: () => {}
            },
            Timestamp: Date.now()
          }
          return result;
        }
      })

  }, { revalidateOnFocus: false });
}

// 不使用useSWR的get 還在研發中 別使用
export const $get2 = ({
  url = '',
  params = {},
  allow = true,
  showMessage = true,
  resCode = {},
  auth = true,
  fail = (data: any) => {},
  success = (data: any) => {},
  mock
}: any): Swr => {

  const { query, headers, token } = requestInformation(params);

  const [data, setData] = useState<GetResponse>();
  const [isValidating, setIsValidating] = useState(false);
  const [timestamp, setTimestamp] = useState<number>(new Date().getTime());

  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      get();
    }
  }, []);

  useEffect(() => {
    if (timestamp) {
      get();
    }
  }, [timestamp, allow]);

  function get() {
    if (allow && ((auth && token) || (!auth))) {
      setIsValidating(true);

      fetch(`/${url}${query}`, {
        method: 'GET',
        headers
      })
      .then(async response => {
        let res = await responseProcessing(response);
        let headers = response.headers;

        if (MOCK_ENABLE && process.env.NODE_ENV === 'development' && mock) {
          const { body, headers: mockHeaders } = mock();
          res = body;
          headers = mockHeaders;
        }

        // 成功
        if (!res.errorCode && res.name !== 'Error') {
          success(res);
          const result: GetResponse = {
            Data: res,
            Page: headers.get('Page'),
            Rows: headers.get('Rows'),
            Total: headers.get('Total'),
            Headers: headers,
            Timestamp: Date.now()
          }
          setData(result);

        // 失敗
        } else {
          errorAlert(res.errorCode, resCode, showMessage);
          fail(res);
          const result: GetResponse = {
            Data: [],
            Page: 1,
            Rows: 30,
            Total: 0,
            Headers: {
              get: () => {}
            },
            Timestamp: Date.now()
          }
          setData(result);
        }

        setIsValidating(false);
      })
    }
  }

  return {
    data,
    isValidating,
    mutate: () => setTimestamp(new Date().getTime())
  }
}

type HttpMethod = 'POST' | 'PUT' | 'PATCH' | 'DELETE'

interface ApiParameters {
  url: string;
  send?: Record<string, any>; 
  allow?: boolean;
  success?: (data: any) => void; 
  fail?: (data: any) => void;
  showMessage?: boolean; 
  resCode?: Record<string, any>; 
  auth?: boolean;
  queryHeader?: boolean;
  mock?: () => void;
}

export const $api = async (
  method: HttpMethod,
  {
    url, 
    send = {},
    showMessage = true, 
    resCode = {}, 
    queryHeader = false,
    success = (data: any) => {}, 
    fail = (data: any) => {},
    mock
  }: ApiParameters,
  setLoading = (b: boolean) => {}
) => {

  setLoading(true);

  const { query, headers } = requestInformation(send);

  let res = await fetch(`/${url}${queryHeader ? query : ''}`, {
    method,
    headers,
    body: JSON.stringify(send)
  })
    .then(async response => await responseProcessing(response))

  if (MOCK_ENABLE && process.env.NODE_ENV === 'development' && mock) res = mock();

  if (!res.errorCode && res.name !== 'Error') {
    success(res);
  } else {
    errorAlert(res.errorCode, resCode, showMessage);
    fail(res);
  }

  setLoading(false);
}
