import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { Link, useRouteError } from "react-router-dom";
import i18n from 'i18n';

import PageSiteSetting from "pages/site-setting.page";
import PageAccount from "pages/account.page";
import PageAnnouncement from "pages/announcement.page";
import PageAnnouncementAdd from "pages/announcement-add.page";
import PageannouncementEdit from "pages/announcement-edit.page";
import PageGameAata from "pages/game-data.page";
import PageGameList from "pages/game-list.page";
import PageGameManagement from "pages/game-management.page";
import PageGameProviderRebate from "pages/game-provider-rebate.page";
import PageGameProviderSort from "pages/game-provider-sort.page";
import PageLoginRecord from "pages/login-record.page";
import PagePermission from "pages/permission.page";
import PagePermissionEdit from "pages/permission-edit.page";
import PageLicense from "pages/license.page";
import PageCustomerServiceSoftwareSetting from "pages/customer-service-software-setting.page"

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const advancedModule = [
  {
    path: '/', ErrorBoundary,
    children: [
      { path: '/advanced/site-setting', element: <PageSiteSetting/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: i18n.t('platformSettings') },
        { title: i18n.t('platformData') },
      ]},
      { path: '/advanced/platform-game-data', element: <PageGameAata/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: i18n.t('platformSettings') },
        { title: i18n.t('gameData') },
      ]},
      { path: '/advanced/platform-provider-sort', element: <PageGameProviderSort/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: i18n.t('platformSettings') },
        { title: i18n.t('gameProviderSorting') },
      ]},
      { path: '/advanced/platform-game-list', element: <PageGameList/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: i18n.t('platformSettings') },
        { title: i18n.t('gameList') },
      ]},
      { path: '/advanced/platform-game-rebate', element: <PageGameProviderRebate/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: i18n.t('platformSettings') },
        { title: i18n.t('gameProviderCashbackSettings') },
      ]},
      { path: '/advanced/platform-license', element: <PageLicense/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: i18n.t('platformSettings') },
        { title: i18n.t('uploadLicense') },
      ]},
      { path: '/advanced/platform-customer-service-software-setting', element: <PageCustomerServiceSoftwareSetting/>, Breadcrumb: [
          { title: i18n.t('advanced') },
          { title: i18n.t('platformSettings') },
          { title: i18n.t('customerServiceSoftwareSetting') },
        ]},
      { path: '/advanced/permission', element: <PagePermission/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: i18n.t('permissionManagement') }
      ]},
      { path: '/advanced/permission/edit/:id', element: <PagePermissionEdit/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: <Link to={'/advanced/permission'}>{i18n.t('permissionManagement')}</Link> },
        { title: i18n.t('edit') }
      ]},
      { path: '/advanced/account/:id?', element: <PageAccount/>
        // 選填 params 麵包屑無法自動化
      },
      { path: '/advanced/announcement', element: <PageAnnouncement/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: i18n.t('systemAnnouncement') }
      ]},
      { path: '/advanced/announcement/system/add', element: <PageAnnouncementAdd/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: <Link to={'/advanced/announcement'}>{i18n.t('systemAnnouncement')}</Link> },
        { title: i18n.t('add') }
      ]},
      { path: '/advanced/announcement/system/edit/:id', element: <PageannouncementEdit/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: <Link to={'/advanced/announcement'}>{i18n.t('systemAnnouncement')}</Link> },
        { title: i18n.t('edit') }
      ]},
      { path: '/advanced/game-management', element: <PageGameManagement/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: i18n.t('gameManagement') }
      ]},
      { path: '/advanced/login-record', element: <PageLoginRecord/>, Breadcrumb: [
        { title: i18n.t('advanced') },
        { title: i18n.t('loginRecords') }
      ]},
    ],
  },
]