import { MEMBER_BANK_STATE } from "constants/state";
import dayjs from "dayjs";
import Decimal from 'decimal.js';
import i18n from "i18n";

// enum 轉成下拉選單(_, 排除項目)
export function enumToOptions(e: any, exclude: any = []) {
  return Object.keys(e)
    .filter(v => isNaN(Number(v)) && !exclude.includes(e[v]))
    .map((k: any) => ({ value: e[k], label: i18n.t(k) }))
}

export function leftPad(str: string, len: number, char: string) {
  while (str.length < len) {
    str = char + str;
  }
  return str;
}

// 欄位驗證
export function verify({
  required = true,
  point = 2,
  max = null,
  min = null,
  negative = false,
  zero = true,
  isShowCompareNumber = false,
  mustMeetPasswordFormat = false
}: any) {
  return [
    { required: required, message: `${i18n.t('required')}` },
    {
      validator: async (record: any, value: any) => {

        let regex = new RegExp('^-?\\d{1,100}(\\.\\d{0,0})?$');
        let msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('onlyIntegersCanBeEntered')}`;

        if (point === 2) {
          regex = new RegExp('^-?\\d{1,100}(\\.\\d{1,2})?$');
          msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('supportsUpToTwoDecimalPlaces')}`;
        } else if (point === 4) {
          regex = new RegExp('^-?\\d{1,100}(\\.\\d{1,4})?$');
          msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('supportsUpToFourDecimalPlaces')}`;
        } else if (point === 5) {
          regex = new RegExp('[a-zA-Z]{1}[a-zA-Z0-9]{3,11}$');
          msg = `4~12${i18n.t('requiresEnglishAndNumbers')}`;
        } else if (point === 6) {
          regex = new RegExp('[a-zA-Z0-9*_-]{4,20}$');
          msg = `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`;
        } else if (point === 7) {
          regex = new RegExp('^[0-9]+$');
          msg = i18n.t('invalidFormat');
        }

        // 只能是數字
        if (!mustMeetPasswordFormat && isNaN(value) && value && point !== 5 && point !== 6 && point !== 7) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 只能是正整數
        if ((!negative && Number(value) < 0) || (point === 0 && !regex.test(value))) {
          return Promise.reject(i18n.t('onlyCanBePositiveIntegers'));
        }

        // 不能為0 但選填
        if (!zero && (typeof value !== 'undefined' && value !== null) && Number(value) === 0) {
          return Promise.reject(`${i18n.t('mustBeGreaterThan')}0`);
        }

        // 正數
        if (!negative && Number(value) < 0) {
          return Promise.reject(i18n.t('contentOnlyNumber'));
        }

        // 小數點
        if (!mustMeetPasswordFormat && value && !regex.test(value)) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 超過最大
        if (isShowCompareNumber && max !== null && parseFloat(value) > parseFloat(max as string)) {
          return Promise.reject(`${i18n.t('mustBeLessThanOrEqualTo')}${max}`);
        }
        if (max !== null && parseFloat(value) > parseFloat(max as string)) {
          return Promise.reject(i18n.t('rateMax'));
        }
        // 限制最小
        if (isShowCompareNumber && min !== null && parseFloat(value) < parseFloat(min as string)) {
          return Promise.reject(`${i18n.t('mustBeGreaterThanOrEqualTo')}${min}`);
        }
        if (min !== null && parseFloat(value) < parseFloat(min as string)) {
          return Promise.reject(i18n.t('rateMin'));
        }
        // 密碼格式判斷 4~20個字符內的英文、數字，支援*_-
        if (mustMeetPasswordFormat) {
          regex = new RegExp('^[a-zA-Z0-9*_-]{4,20}$');
          msg = `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`;
          if (!regex.test(value)) {
            return Promise.reject(msg);
          }
        }

        return Promise.resolve();
      }
    }
  ];
}

// 多語系切換 i: jsonString o:name string
export function gameNameTransfer(json: string, defaultName: string, lang: string) {
  const data = json ? JSON.parse(json) : [];
  const transfer = data.find((item: { lang: string }) => item.lang === lang);
  return transfer ? transfer.name : defaultName;
}

// 通用時間
export function timeL2S(timeString: string, gap: number = 0) {
  return dayjs.tz(timeString).valueOf() + gap;
}

// 通用時間
export function timeS2L(t: any, format: string = 'YYYY-MM-DD HH:mm:ss') {
  const serverZone = window?.serverZone || "Asia/Taipei";
  const timeString = dayjs(`${t}`.includes('Z') ? t : Number(t)).format('YYYY-MM-DDTHH:mm:ss');
  if (!t) return '-';
  return dayjs.tz(timeString, serverZone).tz().format(format);
}

export function timeS2LDayjs(t: any) {
  const serverZone = window?.serverZone || "Asia/Taipei";
  const timeString = dayjs(`${t}`.includes('Z') ? t : Number(t)).format('YYYY-MM-DDTHH:mm:ss');
  return dayjs.tz(timeString, serverZone).tz();
}

// 推移本地與伺服器的時間差
export function tzShift(day: any, format: string) {
  const L = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss'));
  const S = dayjs(dayjs().tz().format('YYYY-MM-DD HH:mm:ss'));
  const diff = L.diff(S, 'hour');
  const finalTime = format.includes('HH:mm') ? day.add(diff, 'hour') : day;
  return finalTime.format(format);
}

// 下拉選單模糊搜尋
export const selectFuzzyFilter = (input: string, option?: { label: string; value: string }) => {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
}

// 內部遊戲商
export function specialProviderName(name: string | any, siteName: string, code?: string) {
  if (code === 'DBL') return 'DB'
  else if (name === 'SELF') return`${siteName}(${code})`
  else return name
}

// 轉換成百分比 1 => 100
export function convertedToPercentage01(num: number | string): string {
  let decimalValue;
  if (typeof num === 'undefined') return ''
  if (typeof num === 'string') {
    const parsedValue = parseFloat(num);
    decimalValue = new Decimal(parsedValue);
  }

  decimalValue = new Decimal(num);

  return decimalValue.mul(100).toFixed(2).toString();
}

// 轉換成百分比 100 => 1
export function convertedToPercentage02(num: number | string): string {
  let decimalValue;
  if (typeof num === 'string') {
    const parsedValue = parseFloat(num);
    if (!isNaN(parsedValue)) {
      decimalValue = new Decimal(parsedValue).toDP(2);
    } else {
      throw new Error('Invalid input: Not a valid number or string that can be parsed into a number.');
    }
  } else if (typeof num === 'number') {
    decimalValue = new Decimal(num).toDP(2);
  } else {
    throw new Error('Invalid input: Input must be a number or a string that can be parsed into a number.');
  }

  return decimalValue.div(100).toString();
}

// 數字格式
export function toFormatNumber(num: any, fraction: number = 4): string {
  const toNum = isNaN(num) ? 0 : Number(num);
  const formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(toNum);
  return formattedNumber;
}

// 數字格式
// 預設四捨五入; 無條件捨去roundType帶入'floor'; 無條件進位roundType帶入'ceil'
export function toFormatNumber2(num: any, fraction: number = 4, roundType?: 'floor' | 'ceil'): string {
  const toNum = isNaN(num) ? 0 : Number(num);
  const factor = Math.pow(10, fraction);
  let roundedNum: number;
  switch (roundType) {
    case 'ceil':
      roundedNum = Math.ceil(toNum * factor) / factor;
      break;
    case 'floor':
      roundedNum = Math.floor(toNum * factor) / factor;
      break;
    default:
      roundedNum = Math.round(toNum * factor) / factor;
      break;
  }
  const formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(roundedNum);
  return formattedNumber;
}

// 正的是綠色
export function numColor03(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) < 0)
    return 'color-down-0'
  else if (Number(num) > 0)
    return 'color-up-0'
  else
    return 'color-04'
}

export function stateColorBank(state: number) {
  switch (state) {
    case MEMBER_BANK_STATE.waitVerify:
      return 'color-padding';
    case MEMBER_BANK_STATE.verificationSuccessfulOpen:
      return 'color-pass';
    case MEMBER_BANK_STATE.verificationSuccessfulClose:
      return 'color-pass';
    case MEMBER_BANK_STATE.verificationFailed:
      return 'color-reject';
    case MEMBER_BANK_STATE.delete:
      return 'color-down-0';
  }
}

// 置換unix最後三位為999
export function unixLast999(num: number) {
  const integerPart = Math.floor(num / 1000);
  return (integerPart * 1000) + 999;
}

// 替換一個句字中所有的符號 可多符號 { 符號: 換成的字 }
export function replaceAllMultiple(str: string, obj: any) {
  for (const x in obj) {
    str = str.replace(new RegExp(x, 'g'), obj[x]);
  }
  return str;
};

// 檢測圖片長寬
export const determineImageAttributes = (file: any, w: number, h: number, { pass, fail }: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e: any) {
      const image: any = new Image();
      image.src = e.target.result;
      image.onload = function () {
        if (!w || (this.width <= w && this.height <= h)) resolve(true);
        else reject();
      };
    };
  })
  .then(pass).catch(fail);
}

// 正的是黑色
export function numColor01(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) > 0)
    return 'color-up'
  else if (Number(num) < 0)
    return 'color-down'
  else
    return 'unchange'
}

// 整理逗號字串雜訊
export function cleanCommaString(str: string) {
  const dirty = str.split(',');
  const clean = dirty.map((item: any) => item.trim()).filter((item: any) => item !== '');
  return clean.join(',');
}

// 移除陣列重複項目
export function removeDuplicates(arr: string[]) {
  return arr.filter((item: any, index: number) => arr.indexOf(item) === index);
}

// 比較兩個物件內容是否相同
export function objectsEqual(a: any, b: any) {
  let result = true;
  const av = Object.values(a);
  const bv = Object.values(b);
  av.forEach((v, i) => {
    if (v !== bv[i]) result = false;
  })
  return result;
}