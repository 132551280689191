import { Button, Col, Form, Image, Input, Modal, Select, Switch, Table } from "antd";
import { Row } from "antd/lib";
import { useForm } from "antd/lib/form/Form";
import { GATEWAY_CODE_WITHDRAW } from "constants/gateway";
import { MEMBER_BANK_STATE } from "constants/state";
import useAccount from "hooks/account.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { $get } from "services";
import * as common from 'utils/common';
import useSiteById from 'hooks/site-id.hook';

export const ViewBank = ({ id, platformId }: any) => {
  const [isOpenAddBankModal, setIsOpenAddBankModal] = useState(false);
  const [currentCard, setCurrentCard] = useState<any>({});
  const { data: $s } = useSiteById(platformId);
  const { data, isValidating } = $get({
    url: `admin/member/sites/${platformId}/members/${id}/withdraw/methods`,
    allow: !!id && !!platformId
  });

  return (
    <Col span={24} className="mt-1">
      <Table
        loading={isValidating}
        size="middle"
        dataSource={
          data?.Data?.filter((item: any) => item.GatewayCode === GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK)
            .map((item: Bank) => ({ key: item.Id, ...item }))
        }
        columns={[
          {
            dataIndex: 'BankName',
            title: i18n.t('bankName'),
            width: 180
          },
          {
            dataIndex: 'Name',
            width: 180,
            title: i18n.t('accountName'),
          },
          {
            dataIndex: 'BankAccount',
            width: 180,
            title: i18n.t('accountNumber'),
          },
          {
            dataIndex: 'PhoneNumber',
            width: 180,
            hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
            title: `${i18n.t('countryCode')} - ${i18n.t('phoneNumber')}`,
            render: (val, { PhonePrefix }: any) => `${PhonePrefix} - ${val}`
          },
          {
            dataIndex: 'Branch',
            width: 200,
            hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
            title: i18n.t('branchName'),
            render: (val) => val || '-'
          },
          {
            dataIndex: 'State',
            width: 200,
            hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
            title: i18n.t('bankCounty'),
            render: (val) => val || '-'
          },
          {
            dataIndex: 'City',
            width: 200,
            hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
            title: i18n.t('city'),
            render: (val) => val || '-'
          },
          {
            dataIndex: 'Status',
            width: 130,
            title: i18n.t('status'),
            render: (val) => (
              <Switch disabled
                checked={val === MEMBER_BANK_STATE.verificationSuccessfulOpen} />
            )
          },
          {
            dataIndex: 'Status',
            width: 130,
            title: i18n.t('verificationStatus'),
            render: (val) =>
              <div className={common.stateColorBank(val)}>{i18n.t(MEMBER_BANK_STATE[val])}</div>
          },
          {
            title: i18n.t('operation'),
            width: 130,
            render: (_, record) => (
              <>
                <Button className="size-12" type="link" onClick={() => {
                  setCurrentCard(record);
                  setIsOpenAddBankModal(true);
                }}>
                  {i18n.t('detail')}
                </Button>
                <Button className="size-12" type="link" disabled>
                  {i18n.t('delete')}
                </Button>
              </>
            )
          },
        ]}
        pagination={false}
      />
      <PopupBankModal isOpen={isOpenAddBankModal} close={() => setIsOpenAddBankModal(false)} bankId={currentCard.Id} />
    </Col>
  )
}

// 新增詳情
export const PopupBankModal = ({ isOpen, close, bankId }: any) => {
  const { platformId } = useParams();
  // 銀行名稱列表
  const { data: _getBankNameList } = $get({
    url: `admin/member/sites/${platformId}/banks`,
    allow: !!platformId
  });
  const [form] = useForm();
  const { data, isValidating } = $get({
    url: `admin/member/sites/${platformId}/withdraw/methods/${bankId}`,
    allow: !!platformId && !!bankId
  });

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        BankName: data.Data.BankName,
        Account: data.Data.Account,
        Name: data.Data.Name,
        Branch: data.Data.Branch,
        State: data.Data.State,
        City: data.Data.City,
        PhonePrefix: data.Data.PhonePrefix ? data.Data.PhonePrefix.replace('+', '') : '',
        PhoneNumber: data.Data.PhoneNumber,
      });
    }
  }, [data, isOpen]);

  return (
    <Modal
      title={i18n.t('bankCard')}
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={false}
      forceRender
      loading={isValidating}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Form.Item name="BankName" label={i18n.t('bankName')}>
              <Select
                className="w-full"
                placeholder={`${i18n.t('inputData')}`}
                options={_getBankNameList?.Data.map((item: any) => {
                  return { value: item.Code, label: i18n.t(item.Name) }
                })}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Account" label={i18n.t('accountNumber')}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Name" label={i18n.t('bankAccountName')}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Branch" label={i18n.t('branchName')}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="State" label={i18n.t('bankCounty')}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="City" label={i18n.t('city')}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name="PhonePrefix" label={i18n.t('phoneNumber')}>
              <Input autoComplete="off" disabled placeholder={`${i18n.t('enterCountryCode')}`} maxLength={50} addonBefore={'+'} />
            </Form.Item>
          </Col>
          <Col span={15}>
            <Form.Item name="PhoneNumber" label={' '}>
              <Input disabled placeholder={`${i18n.t('inputMobile')}`} maxLength={50} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" className="pt-1">
          <Button onClick={close}>{i18n.t('cancel')}</Button>
        </Row>
      </Form>
    </Modal>
  )
}

// 會員虛擬貨幣列表
export const ViewVirtualCurrency = ({ id, platformId }: any) => {
  const { permissions: $p } = useAccount();
  const [isOpenAddBankModal, setIsOpenAddBankModal] = useState(false);
  const [currentCard, setCurrentCard] = useState<any>({});

  const { data, isValidating } = $get({
    url: `admin/member/sites/${platformId}/members/${id}/withdraw/methods`,
    allow: !!id && !!platformId
  });

  return (
    <>
      <Col span={24} className="mt-1">
        <Table
          loading={isValidating}
          size="middle"
          dataSource={
            data?.Data?.filter((item: any) => item.GatewayCode === GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_CRYPTO)
              .map((item: Bank) => ({ key: item.Id, ...item }))
          }
          columns={[
            {
              dataIndex: 'Crypto',
              title: i18n.t('currency'),
              width: 360,
            },
            {
              dataIndex: 'CryptoType',
              title: i18n.t('networkType'),
              width: 360,
            },
            {
              dataIndex: 'CryptoAddress',
              title: i18n.t('userAddress'),
              width: 400,
            },
            {
              dataIndex: 'Photo',
              title: i18n.t('QRCode'),
              width: 200,
              render: (val) => (val && JSON.parse(val)[0])
                ? <Image className="center" src={JSON.parse(val)[0]} width={25} height={25} /> : '-'
            },
            {
              dataIndex: 'Status',
              title: i18n.t('status'),
              width: 130,
              render: (val, record: any) => (
                <>
                  {
                    record.MerchantStatus === MEMBER_BANK_STATE.verificationFailed
                      ? <Switch disabled />
                      : <Switch
                        checked={val === MEMBER_BANK_STATE.verificationSuccessfulOpen}
                        disabled
                      />
                  }
                </>
              )
            },
            {
              dataIndex: 'Status',
              title: i18n.t('verificationStatus'),
              width: 130,
              render: (val, { MerchantStatus }) => (
                <>
                  {
                    MerchantStatus === MEMBER_BANK_STATE.verificationFailed
                      ? <div className={common.stateColorBank(MerchantStatus)}>
                        {i18n.t('thisNetworkTypeHasBeenDeleted')}</div>
                      : <div className={common.stateColorBank(val)}>{i18n.t(MEMBER_BANK_STATE[val])}</div>
                  }
                </>
              )
            },
            {
              title: i18n.t('operation'),
              width: 130,
              render: (_, record) => (
                <>
                  <Button className="size-12" type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsOpenAddBankModal(true);
                  }}>
                    {i18n.t('detail')}
                  </Button>
                  <Button className="size-12" type="link" disabled>
                    {i18n.t('delete')}
                  </Button>
                </>
              )
            },
          ]}
          pagination={false}
        />
      </Col>
      <PopupVirtualModal isOpen={isOpenAddBankModal} close={() => setIsOpenAddBankModal(false)} bankId={currentCard.Id} />
    </>
  )
}

// 虛擬貨幣新增詳情
export const PopupVirtualModal = ({ isOpen, close, bankId }: any) => {
  const { platformId } = useParams();
  const [form] = useForm();

  const { data, isValidating } = $get({
    url: `admin/member/sites/${platformId}/withdraw/methods/${bankId}`,
    allow: !!platformId && !!bankId
  });


  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        Crypto: data?.Data.Crypto,
        CryptoType: data?.Data.CryptoType,
        CryptoAddress: data?.Data.CryptoAddress,
      });
    }
  }, [data, isOpen]);

  return (
    <Modal
      title={i18n.t('virtualCurrency')}
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={false}
      forceRender
      loading={isValidating}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Form.Item name="Crypto" label={i18n.t('currency')}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CryptoType" label={i18n.t('networkType')}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CryptoAddress" label={i18n.t('userAddress')}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Button onClick={close}>{i18n.t('cancel')}</Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
