import { Select } from "antd";  
import { selectFuzzyFilter } from "../utils/common";
import i18n from "../i18n";
import { $get } from "services";
import { useState } from "react";

export const SiteCodeAndName = ({ name, form, apiUrl, handleChangeFunction = () => {}, mode, disabled = false, className } : {
  name: any;
  form?: any;
  apiUrl: string;
  handleChangeFunction?: any;
  mode?: any;
  disabled?: boolean;
  className?: string;
}) => {
  const [siteId, setSiteId] = useState<number | undefined>();

  const { data: siteList } = $get({ url: apiUrl });

  const handleChange = (e: any) => {
    setSiteId(e);
    // 多選
    if (mode === 'multiple' && form) {
      const value = form.getFieldsValue()[name];
      // 按下全部
      if (!value?.includes('') && e.includes('')) { 
        const siteListIds = siteList?.Data.map((item: any) => item.id) || [];
        form.setFieldsValue({ [name]: ['', ...siteListIds] });

      // 取消全部
      } else if (value?.includes('') && !e.includes('')) {
        form.setFieldsValue({ [name]: null });

      } else {
        form.setFieldsValue({ [name]: e.filter((itme: any) => itme !== '') });
      }

    // 單選
    } else {
      handleChangeFunction(e);
    }
  };

  return (
    <Select
      disabled={disabled}
      mode={mode}
      className={className}
      value={form?.getFieldValue(name) || siteId}
      showSearch
      filterOption={selectFuzzyFilter}
      placeholder={i18n.t('platform')}
      onChange={handleChange}
      options={[
        ...(mode === 'multiple' ? [{ value: '', label: i18n.t('ALL') }] : []),
        ...(siteList?.Data?.map((item: any) => ({
            value: item.id,
            label: `${item.code} - ${item.name}`,
        })) || []),
      ]}/>
  )
}