const main = function() {

  const headers: any = {
    Page: 2,
    Rows: 10,
    Total: 16,
    get: function(key: string) {
      return this[key];
    }
  };

  const body = {
    "NewMemberCount": 1234,
    "ActiveMemberCount": 54321
  };

  return {
    body,
    headers
  }
}

export default main;
