const main = function() {

  const headers: any = {
    Page: 2,
    Rows: 10,
    Total: 16,
    get: function(key: string) {
      return this[key];
    }
  };

  const body = [
    {
      "Role": 0,
      "Account": "",
      "PhonePrefix": "+886",
      "PhoneNumber": "953907729",
      "Content": "這是mock data 測試測試測試測試測試測試測試測試測試測試測試測試測試測試測試測試",
      "Status": 1,
      "SendTime": 1736156589000,
      "Merchant": "mock"
    },
    {
      "Role": 0,
      "Account": "bebe0022",
      "PhonePrefix": "+886",
      "PhoneNumber": "91283123",
      "Content": "SMS_MOBILE_PHONE_VERIFICATION_MESSAGE_TEMPL：936591",
      "Status": 0,
      "SendTime": 1735714321000,
      "Merchant": "mock"
    }
  ];

  return {
    body,
    headers
  }
}

export default main;
