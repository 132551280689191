export enum ENABLE {
  disabled = 0,
  enabled = 1
}

export enum SMS_STATE {
  processing = 0,
  success = 1,
  fail = 2,
}

export enum MODE {
  add = 1,
  edit = 2,
  detail = 3,
  review = 4
}





export enum AGENT_STATE {
  waitApproval = 0,
  normal = 1,
  frozen = 2
}

export enum AGENT_WITHDRAW_STATE {
  pendingDistribution = 0,
  distributing = 1,
  reject = 2,
  dispatched = 3,
  received = 4
}

export enum MEMBER_FREEZE_REPORT {
  normal = 0,
  frozen = 1
}
export enum MEMBER_FREEZE {
  normal = 2,
  frozen = 3
}

export enum MEMBER_STATE {
  waitApproval = 0,
  approvaled = 1
}

export enum MEMBER_BANK_STATE {
  verificationFailed = -1,
  waitVerify = 0,
  verificationSuccessfulOpen = 1,
  verificationSuccessfulClose = 2,
  delete = 9
}

export enum REBATE_PERIOD {
  dailyStatement = 0,
  weeklyStatement = 1,
  monthlySettlement = 2
}

export enum CONTACT_INFO {
  Line,
  WeChat,
  QQ,
  Zalo,
  Messenger,
  Whatsapp,
  Telegram
}

export enum SOURCE_ROLE {
  member = 0,
  agent = 1,
  company = 2
}

export enum REPORT_STATE {
  notSettle = 0,
  settled = 1,
  unsettledCancellation = -1,
  settledCancellation = 2
}