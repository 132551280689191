const main = {

  headers: {
    Page: 1,
    Rows: 30,
    Total: 22,
    get: function(key: string) {
      return this[key];
    }
  } as any,

  "sites/1/licenses": function() {
    const body: any = Array.from({ length: 22 }, (_, i) => ({
      "Id": i,
      "Position": i,
      "Title": "中文中文中文中文中文中文中文中文中文中文中文中文中2文中文中文中文中文中文中文中文",
      "photo_1": "https://ants3.s3.ap-northeast-1.amazonaws.com/contents/rNkFCeR8aR.jpg?1718156809590",
      "LinkURL": "",
      "Status": Math.floor(Math.random() * 2),
      "Lang": "zh-TW"
    }));

    return {
      body,
      headers: main.headers
    };
  }
};

export default main;
