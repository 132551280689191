import { Button, Col, Form, Input, Menu, MenuProps, Row, Select, Spin, Switch, Table, message } from "antd";
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PlatformLayout } from 'components/advanced.component';
import { LayoutNav, LayoutPagination} from 'components/layout.component';
import React, { useState, useEffect } from 'react';
import { $api, $get } from "services";
import { useSelector } from "react-redux";
import { RootType } from "../hooks/store.hook";
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import { RESPONSE_CODE_GAME } from 'constants/response';
import { gameNameTransfer, specialProviderName, verify } from 'utils/common';
import useSiteById from "hooks/site-id.hook";
import useLanguage from "hooks/language.hook";

// 站台設置: 遊戲清單

const PageMain: React.FC = () => {
  const [siteId, setSiteId] = useState(useSelector((state: RootType) => state.Select.siteId) || null);
  const { data: site, isValidating: siteValidating } = useSiteById(siteId);
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<any>([])
  const [isEdit, setIsEdit] = useState(false);
  const [editingKey, setEditingKey] = useState<number>(0);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [providerType, setProviderType] = useState<string[]>([]);
  const [position, setPosition] = useState<number | null>();
  const [label, setLabel] = useState<number[]>([]);
  const [isMulti, setIsMulti] = useState<number | null>(null);

  const { getLang: lang } = useLanguage();
  
  // 遊戲商列表
  const { data: provider } = $get({ url: `admin/game-list/sites/${siteId}/providers`, allow: !!siteId});
  const { data: GameInfo, isValidating, mutate } = $get({
    url: `admin/game-list/sites/${siteId}/games`,
    params: {
      providerCode: providerType[0],
      categoryCode: providerType[1],
      rows: page[1],
      page: page[0]
    },
    allow: !!siteId && !!(providerType && providerType[0] && providerType[1])
  });

  useEffect(() => {
    if (GameInfo) {
      setDataSource(
        GameInfo.Data.map((item: any) => ({
          key: item.Id,
          gameName: item.Lang,
          ...item,
        }))
      )
    }
  }, [GameInfo])

  useEffect(() => {
    if (providerType) {
      setIsEdit(false);
    }
  }, [providerType]);

  const handleEdit = (record: any) => {
    setLabel(JSON.parse(record.Label));
    setEditingKey(record.Id);
    setIsEdit(true);
    setPosition(record.Position);
    form.setFieldValue(`sort${record.Id}`, record.Position);
  };

  const handleSave = (record: any) => {
    $api('PUT', {
      url: `admin/game-list/sites/${siteId}/games`,
      send: {
        id: record.key,
        position: position ? Number(position) : record.Position,
        label: label,
        isMultiGameConsole: isMulti !== null ? isMulti : record.IsMultiGameConsole
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        mutate();
        setIsEdit(false);
        setEditingKey(0);
        setPosition(null);
        setIsMulti(null);
      },
      resCode: RESPONSE_CODE_GAME
    })
  }

  const onCancel = (info: any) => {
    const data: number[] = JSON.parse(info.Label);
    const select: number[] = data?.filter((item: number) => item !== 0)
    if (info.key === editingKey) {
      setIsEdit(false);
      setEditingKey(0);
      setPosition(null);
      setIsMulti(null);
      form.setFieldValue(`tags${info.key}`, select);
      form.setFieldValue(`isMulti${info.key}`, info.IsMultiGameConsole);
      form.setFieldValue(`sort${info.key}`, info.Position);
    }
  }

  const onSetGameMulti = (id: number, status: boolean) => {
    $api('PATCH', {
      url: `admin/game-list/sites/${siteId}/games/multi`,
      send: {
        id: id,
        isMultiGameConsole: status ? 1 : 0
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_GAME
    })
  }

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => (
    setOpenKeys([keys[keys.length - 1]])
  );

  const onClickGameMenuItem: MenuProps['onClick'] = (e) => {
    const key = e.keyPath[0].split('$$');
    setProviderType([key[0], key[1]]);
    setPage([1, page[1]]);
  };

  type MenuItem = Required<MenuProps>['items'][number];
  const [gameMenuItems, setGameMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (provider?.Data && site.SiteName) {
      const MenuItemObject: any = {};

      provider.Data.forEach((item: ProviderType) => {
        const label = specialProviderName(item.Name, site.SiteName, item.Code);

        if (!MenuItemObject[label]) {
          MenuItemObject[label] = {
            label,
            children: [
              {
                key: `${item.Code}$$${item.CategoryCode}`,
                label: i18n.t(item.CategoryCode)
              }
            ]
          }
        } else {
          MenuItemObject[label].children.push({
            key: `${item.Code}$$${item.CategoryCode}`,
            label: i18n.t(item.CategoryCode)
          })
        }

      })
      
      setGameMenuItems(Object.values(MenuItemObject));
    }
  }, [provider, site])

  // 只有斯洛跟魚機有多台
  // const multipleDevices = providerType[1] === 'SLOT' || providerType[1] === 'FISHING';

  return (
    <div id="container">
      <Content>
        <PlatformLayout tabKey={'4'} setSiteIdOutside={setSiteId} />
        {!!siteId &&
         <Form form={form}>
          <Spin spinning={isValidating || siteValidating}>
            <Row gutter={[12, 12]}>
              {/* 遊戲商 */}
              <Col span={4}>
                <Menu
                  id="gameMenu"
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  onClick={onClickGameMenuItem}
                  mode="inline"
                  items={gameMenuItems}
                  disabled={isEdit}
                />
              </Col>
              {/* 遊戲列表 */}
              <Col span={20}>
                {providerType.length !== 0 &&
                  <>
                    <Table
                      size="middle"
                      dataSource={dataSource || []}
                      columns={[
                        {
                          dataIndex: 'frontSort',
                          title: i18n.t('frontSort'),
                          width: 100,
                          render: (_: any, record: any) =>  (isEdit && record.key === editingKey)
                            ?
                            <Form.Item name={`sort${record.key}`} rules={[...verify({ point: 0, required: false, min: 0, max: 99 })]}>
                              <Input defaultValue={record.Position} onChange={e => setPosition(Number(e.target.value))} />
                            </Form.Item>
                            : record.Position
                        },
                        {
                          dataIndex: 'gameName',
                          title: i18n.t('gameName'),
                          width: 400,
                          render: (_: any, { gameName, Name }: any) => gameNameTransfer(gameName, Name, lang)
                        },
                        // {
                        //   // 支援多台
                        //   dataIndex: 'supportMultipleDevices',
                        //   title: i18n.t('supportMultipleDevices'),
                        //   align: 'center',
                        //   width: 100,
                        //   hidden: !multipleDevices,
                        //   render: (_: any, record: any) => (
                        //     <Form.Item name={`isMulti${record.key}`} valuePropName="checked">
                        //       <Switch
                        //         disabled={!$p('gameList.updateGame')}
                        //         defaultChecked={!!record.IsMultiGameConsole}
                        //         onChange={e => {
                        //           setIsMulti(e ? 1 : 0);
                        //           onSetGameMulti(record.key, e);
                        //         }}
                        //       />
                        //     </Form.Item>
                        //   )
                        // },
                        // 遊戲設置
                        {
                          dataIndex: 'gameSetting',
                          title:
                            <>
                              {i18n.t('gameSetting')}
                              <span className="color-03">({i18n.t('selectableMultiple')})</span>
                            </>,
                          width: 300,
                          render: (_: any, record: any) => {
                            const data: number[] = JSON.parse(record.Label);
                            const select: number[] = data?.filter((item: number) => item !== 0);
                            return (
                              <Form.Item name={`tags${record.key}`} >
                                <Select
                                  disabled={record.key !== editingKey || !isEdit}
                                  mode="tags"
                                  tokenSeparators={[',']}
                                  defaultValue={select}
                                  className="w-full"
                                  placeholder={`${i18n.t('pleaseSelect')}`}
                                  onChange={setLabel}
                                  options={[
                                    { value: 1, label: i18n.t('newGame') },
                                    { value: 2, label: i18n.t('hotGames') },
                                    { value: 3, label: i18n.t('recommend') },
                                  ]}
                                />
                              </Form.Item>
                            )
                          }
                        },
                        {
                          dataIndex: 'operation',
                          title: i18n.t('operation'),
                          width: 100,
                          fixed: 'right',
                          align: 'center',
                          render: (_: any, record: any) => (
                            <>{
                              (isEdit && record.key === editingKey)
                                ?
                                <>
                                  <Button type="link" onClick={() => handleSave(record)}>
                                    {i18n.t('done')}
                                  </Button>
                                  <Button type="link" onClick={() => onCancel(record)}>
                                    {i18n.t('cancel')}
                                  </Button>
                                </>
                                :
                                <Button type="link" onClick={() => handleEdit(record)}
                                        disabled={isEdit && record.key !== editingKey}>
                                  {i18n.t('edit')}
                                </Button>
                            }</>
                          )
                        }
                      ].filter((item: any) => !(item.dataIndex === 'operation' && !$p('gameList.updateGame'))) as any}
                      pagination={false}
                    />
                    <LayoutPagination total={GameInfo?.Total } setPage={setPage} page={page} />
                  </>
                }
              </Col>
            </Row>
          </Spin>
          </Form>}
      </Content>
    </div>
  );
};

export default PageMain;