import { Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ViewBank, ViewVirtualCurrency } from 'components/bank.component';
import { LayoutNav } from 'components/layout.component';
import { LayoutTabMember } from 'components/member.component';
import React from 'react';
import { useParams } from 'react-router-dom';

// 會員列表: 銀行卡列表

const PageMain: React.FC = () => {
  const { id, platformId, account, agId } = useParams();

  return (
    <div id="container">
      <Content>
        <LayoutTabMember activeKey="4" id={id} platformId={platformId} account={account} agId={agId} />
        <Row>
          <ViewBank id={id} platformId={platformId} />
          <ViewVirtualCurrency id={id} platformId={platformId} />
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;