const main = function() {

  const headers: any = {
    Page: 1,
    Rows: 10,
    Total: 22,
    get: function(key: string) {
      return this[key];
    }
  };

  const body: any = [
    {
      "Id": 1,
      "Code": "Default",
      "Type": "Url",
      "Data": "{}",
      "IsEnabled": 1,
      "LastModifyAccountId": 2,
      "CreateDate": "2024-11-25 15:02:54",
      "UpdateDate": "2025-01-06 13:29:57"
    },
    {
      "Id": 2,
      "Code": "Line",
      "Type": "Url",
      "Data": "{\"Url\": \"https://page.line.me/789rbibc\"}",
      "IsEnabled": 1,
      "LastModifyAccountId": 2,
      "CreateDate": "2024-11-25 15:02:54",
      "UpdateDate": "2025-01-06 13:29:57"
    },
    {
      "Id": 3,
      "Code": "Telegram",
      "Type": "Url",
      "Data": "{\"Url\": \"https://t.me/s/tod3028\"}",
      "IsEnabled": 1,
      "LastModifyAccountId": 2,
      "CreateDate": "2024-11-29 13:41:46",
      "UpdateDate": "2025-01-06 13:29:57"
    },
    {
      "Id": 4,
      "Code": "Skype",
      "Type": "Url",
      "Data": "{\"Url\": \"https://tw.yahoo.com/\"}",
      "IsEnabled": 1,
      "LastModifyAccountId": 2,
      "CreateDate": "2024-11-29 13:41:46",
      "UpdateDate": "2025-01-06 13:29:57"
    },
    {
      "Id": 5,
      "Code": "Whatsapp",
      "Type": "Url",
      "Data": "{\"Url\": \"https://tw.yahoo.com/\"}",
      "IsEnabled": 1,
      "LastModifyAccountId": 2,
      "CreateDate": "2024-11-29 13:41:46",
      "UpdateDate": "2025-01-06 13:29:57"
    },
    {
      "Id": 6,
      "Code": "Messenger",
      "Type": "Url",
      "Data": "{\"Url\": \"https://tw.yahoo.com/\"}",
      "IsEnabled": 1,
      "LastModifyAccountId": 2,
      "CreateDate": "2024-11-29 13:41:46",
      "UpdateDate": "2025-01-06 13:29:57"
    },
    {
      "Id": 7,
      "Code": "Instagram",
      "Type": "Url",
      "Data": "{\"Url\": \"https://www.instagram.com/imayday55555\"}",
      "IsEnabled": 1,
      "LastModifyAccountId": 2,
      "CreateDate": "2024-11-29 13:41:46",
      "UpdateDate": "2025-01-06 13:29:57"
    },
    {
      "Id": 8,
      "Code": "Zalo",
      "Type": "Qrcode",
      "Data": "{\"QRCode\": \"https://ants3.s3.ap-northeast-1.amazonaws.com/customerservicesoftware/EG1Yfn7Xuj.png?1733392425515\"}",
      "IsEnabled": 1,
      "LastModifyAccountId": 2,
      "CreateDate": "2024-11-29 13:41:46",
      "UpdateDate": "2025-01-06 13:29:57"
    },
    {
      "Id": 9,
      "Code": "Meiqia",
      "Type": "Secret",
      "Data": "{\"entId\": \"de505515b8c3bb895672c07e1e372999\"}",
      "IsEnabled": 0,
      "LastModifyAccountId": 2,
      "CreateDate": "2024-11-29 13:41:46",
      "UpdateDate": "2025-01-06 13:29:57"
    }
  ];

  return {
    body,
    headers
  };

};

export default main;
