export enum MEMBER_LOG {
  bet = 2002,
  payout = 2004,
  depositing = 1006,
  withdrawal = 1011,
  withdrawalDenied = 1012,
  adjustmentAddBalance = 1001,
  adjustmentDeductBalance = 1002,
  memberLevelPromotion = 2020,
  systemDiscountsDistributed = 1015,
  dddPromotion = 1018,
  deductPromotion = 1019,
  rebate = 1020,
  transferInput = 2018,
  transferOutput = 2017,
  transferIn = 1031,
  transferOut = 1032,
  raise = 2102,
  donate = 2016,
  unsettledCancellation = 2003,
  settledCancellation = 2008,
  cancelPayout = 2005,
  providerPromotions = 2015,
  personalRebate = 1040,
  rebateForDirectDownlineValidMembers = 1042,
  personalRebateRecalculation= 1041,
  rebateForDirectDownlineValidMembersRecalculation= 1043,
  cashbackDeposit = 1020,
  cashbackDeduction= 1021,
  cashbackAddRollover= 1022
}