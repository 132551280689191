const main = function() {

  const headers: any = {
    Page: 2,
    Rows: 10,
    Total: 16,
    get: function(key: string) {
      return this[key];
    }
  };

  const body = {
    "TotalFirstDepositAmount": 123456789,
    "TotalFirstTimeDepositMemberCount": 321,
    "TotalSecondTimeDepositMemberCount": 4321
  };

  return {
    body,
    headers
  }
}

export default main;
