import { Button, Checkbox, Col, Form, Modal, Popover, Row, Select, Space, Spin, Table, Tooltip, Tabs } from 'antd';
import { DatabaseFilled } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import i18n from 'i18n';
import { $get } from "services";
import { LayoutPagination } from './layout.component';
import useIcon from 'hooks/icon.hook';
import useAccount from 'hooks/account.hook';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { convertedToPercentage01 as $g, toFormatNumber } from "utils/common";
import { CopyButton, Export } from 'components/layout.component';
import { timeS2L } from 'utils/common';
import { MEMBER_FREEZE } from 'constants/state'
import { SETTLE_CYCLE } from "constants/promotion"
import { ExcelColumns } from "constants/excel"
import { WALLET_TRANSLATE } from 'constants/gateway';

export const GameCategorySelect = ({ name, form, mode, disabled, code, siteId }: {
  name: any;
  form: any
  mode?: any
  disabled?: boolean;
  code?: boolean;
  siteId?: any;
}) => {
  // 取得遊戲類別列表
  const { data } = $get({
    url: `admin/game/sites/${siteId}/categories`,
    allow: !!siteId
  },
  )
  const $s = (val: any) => mode === 'multiple' ? val?.sort((a: number, b: number) => a - b) : val

  return (
    <Select
      value={$s(form.getFieldValue(name))}
      mode={mode}
      disabled={disabled}
      placeholder={i18n.t("gameCategory")}
      className="w-full"
      options={[
        { value: '', label: i18n.t('allGameCategory') },
        ...(data?.Data || []).map((item: any) => (
          { value: item.Code, label: i18n.t(item.Name) }
        ))
      ].filter(item => !(mode === 'multiple' && item.value === ''))}
      onChange={val => form.setFieldValue(name, $s(val))}
    />
  )
}

export const LayoutTabMember = ({ activeKey, id, platformId, account, agId}: {
  activeKey: string;
  id?: number | string;
  platformId?: number | string;
  account?: string;
  agId?: string;
}) => {
  const { permissions: $p } = useAccount();

  const navigate = useNavigate();
  const onTabClick = (key: any) => {
    switch (key) {
      case '1':
        navigate(`/member/info/${platformId}/${id}/${account}/${agId}`);
        break;
      case '2':
        navigate(`/member/provider/${platformId}/${id}/${account}/${agId}`);
        break;
      case '3':
        navigate(`/member/contact/${platformId}/${id}/${account}/${agId}`);
        break;
      case '4':
        navigate(`/member/bank/${platformId}/${id}/${account}/${agId}`);
        break;
      case '5':
        navigate(`/member/rebate/${platformId}/${id}/${account}/${agId}`);
        break;
    }
  }
  const items: any = [
    $p('memberInformation.getMember') && 
    { key: '1', label: i18n.t('basicData') },
    $p('memberProviderSetting.getMemberProviderSettingList') && 
    { key: '2', label: i18n.t('gameProviderData') },
    $p('memberContact.getMemberContactList') && 
    { key: '3', label: i18n.t('contactInformation') },
    $p('memberWithdrawMethohdList.getMemberWithdrawMethodList') && 
    { key: '4', label: i18n.t('bankCardList') },
    $p('memberOperatParams.getMemberOperatParams') && 
    { key: '5', label: i18n.t('rebateList') },
  ];

  return (
    <Tabs className="color-03" size="small" activeKey={activeKey} items={items} onTabClick={onTabClick} />
  );
};

export const MemberLevelSelect = ({ name, form, mode, disabled, bonusData, all = true, platformId }: {
  name: any;
  form: any
  mode?: any
  disabled?: boolean;
  bonusData?: any;
  all?: boolean;
  platformId?: number | null | undefined;
}) => {
  const { data } = $get({
    url: `admin/member/sites/${platformId}/member/levels`,
    allow: !!platformId
  });
  const $s = (val: any) => mode === 'multiple' ? val?.sort((a: number, b: number) => a - b) : val

  return (
    <Select
      value={$s(form.getFieldValue(name))}
      mode={mode}
      disabled={disabled}
      placeholder={mode === 'multiple' ? i18n.t('pleaseSelectMemberLevel') : i18n.t('memberLevel')}
      className="w-full"
      options={
        all
          ? [{ value: '', label: i18n.t('ALL') },
          ...(bonusData || data?.Data || []).map((item: any) => (
            { value: item.Id, label: item.Name }
          ))
          ].filter(item => !(mode === 'multiple' && item.value === ''))
          : [...(bonusData || data?.Data || []).map((item: any) => (
            { value: item.Id, label: item.Name }
          ))
          ].filter(item => !(mode === 'multiple' && item.value === ''))
      }
      onChange={val => form.setFieldValue(name, $s(val))}
    />
  )
}

// 會員備註
export const ViewMemberMemoList = ({ id, platformId }: {
  id: number | string;
  platformId?: number | string;
}) => {
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);

  const { data, isValidating } = $get({
    url: `admin/member/sites/${platformId}/members/${id}/memos`,
    params: {
      page: page[0],
      rows: page[1]
    },
    allow: !!id && !!platformId
  });

  return (
    <Form form={form} component={false}>
      <div className="size-14 font-w-md">{i18n.t('remarkHistory')}</div>
      <Table
        loading={isValidating}
        className="mt-1"
        size="middle"
        dataSource={data?.Data.map((item: Memo) => ({ ...item, key: item.Id }))}
        columns={[
          {
            dataIndex: 'Note',
            title: i18n.t('remark'),
          },
          {
            dataIndex: 'OperatorAccount',
            title: i18n.t('updater'),
            width: 300,
            render: (val: string) => val || 'System'
          },
          {
            dataIndex: 'UpdateDate',
            title: i18n.t('updateTime'),
            width: 300,
            render: (val: string) => timeS2L(val)
          },
          {
            title: i18n.t('operation'),
            width: 160,
            render: (_: any) => {
              return (
                <Row>
                  <Col>
                    <Button type="link" disabled>{i18n.t('edit')}</Button>
                  </Col>
                  <Col>
                    <Button className="size-12" type="link" disabled>{i18n.t('delete')}</Button>
                  </Col>
                </Row>
              )
            },
          },
        ]}
        pagination={false}
      />
      <LayoutPagination total={data?.Total || 0} setPage={setPage} page={page} />
    </Form>
  )
}

// 會員登入記錄
export const PopupMemberLoginHistory = ({ isOpen, close, id, account, platformId }: {
  isOpen: boolean;
  close: () => void;
  id: number | string;
  account: string;
  platformId?: number | string;
}) => {
  const { data: loginHistory, isValidating } = $get({
    url: `admin/member/sites/${platformId}/members/${id}/login/logs`,
    allow: !!id && !!platformId
  });

  return (
    <Modal
      title={`${account} ${i18n.t('loginTime')}`}
      open={isOpen}
      onCancel={close}
      width={600}
      centered
      footer={
        <Row justify="center">
          <Button type="primary" key="cancel" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        <Table
          size="small"
          bordered
          scroll={{ y: 400 }}
          dataSource={
            loginHistory?.Data.map((item: LoginHistory) => ({ ...item, key: item.Id }))
          }
          columns={[
            {
              title: i18n.t('loginTime'),
              dataIndex: 'CreateDate',
              width: 140,
              render: (val) => timeS2L(val)
            },
            {
              title: 'IP',
              dataIndex: 'IP',
              width: 140
            },
            {
              title: i18n.t('IP Geo Address'),
              dataIndex: 'IPLocation',
              width: 140
            },
          ]}
          pagination={false}
        />
      </Spin>
    </Modal>
  )
}

// 會員列表
export const ViewMemberList = ({ data, mutate, searchParam, isValidating, siteId, triggerApi }: {
  data: any;
  isValidating: any;
  mutate: () => void;
  searchParam: any;
  siteId? : any;
  triggerApi?: any;
}) => {
  const icon = useIcon();
  const navigate = useNavigate();
  const { init, permissions: $p } = useAccount();

  // 會員在線人數
  const { data: OnlineMembers } = $get({
    url: `admin/member/sites/${siteId}/online/count`,
    allow: !!siteId && triggerApi
    });

  // 是否會員返水設定值吃會員vip等級(會員返水設定值吃會員vip等級則不顯示返水欄位)
  const [isRebateDependOnVipLevel, setIsRebateDependOnVipLevel] = useState(false);
  const commissionmodule = data?.Headers.get('Commissionmodule');

  useEffect(() => {
    if (commissionmodule) {
      setIsRebateDependOnVipLevel(commissionmodule === '2');
    }
  }, [commissionmodule]);

  const [columns, setColumns] = useState<any>([]);

  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.dataIndex === key) {
        item.show = checked
        return true
      }
    })
    setColumns([...columns]);
  };

  const waterHover = (record: any, word: any) => <>
    <Tooltip placement="bottom" title={
      <Spin spinning={false}>
        <div>{`${i18n.t('EGAMES')}: ${$g(record.EGamesCommissionRate)}%`}</div>
        <div>{`${i18n.t('LIVE')}: ${$g(record.LiveCommissionRate)}%`}</div>
        <div>{`${i18n.t('SPORTS')}: ${$g(record.SportsCommissionRate)}%`}</div>
        <div>{`${i18n.t('ESPORTS')}: ${$g(record.ESportsCommissionRate)}%`}</div>
        <div>{`${i18n.t('POKER')}: ${$g(record.PokerCommissionRate)}%`}</div>
        <div>{`${i18n.t('FISHING')}: ${$g(record.FishingCommissionRate)}%`}</div>
        <div>{`${i18n.t('COCKFIGHT')}: ${$g(record.CockfightCommissionRate)}%`}</div>
        <div>{`${i18n.t('LOTTERY')}: ${$g(record.LotteryCommissionRate)}%`}</div>
        <div>{`${i18n.t('SLOT')}: ${$g(record.SlotCommissionRate)}%`}</div>
      </Spin>
    }>
      {word}
    </Tooltip>
  </>

  useEffect(() => {
    if (init && siteId && data) {
      setColumns([
        {
          dataIndex: 'Account',
          title: i18n.t('memberAccount'),
          width: 120,
          fixed: 'left',
          show: true,
          render: (val: any, record: any) => (
            <Row justify="start" align="middle">
              <Popover
                title={`${i18n.t('memberInformation')} (${val})`}
                placement="bottomLeft"
                content={() => (
                  <Space style={{ minWidth: 200 }} direction="vertical">
                    <div className='text-start size-12'>
                      <div>{i18n.t('realName')}：{record.Name || '-'}</div>
                      {/* <div>{i18n.t('nickname')}：{record.Nickname || '-'}</div> */}
                      <div>{i18n.t('identifier')}：{record.NoCode}</div>
                      <div>{i18n.t('birthday')}：{record.Birth && record.Birth !== '0000-00-00' ? record.Birth : '-'}</div>
                      <div>{i18n.t('idNum')}：{record.IDNumber || '-'}</div>
                      <div>{i18n.t('phoneNumber')}：{record.PhoneNumber ? `${record.PhonePrefix}-${record.PhoneNumber}` : '-'}</div>
                      <div>{i18n.t('email')}：{record.Email || '-'}</div>
                      {
                        $p('10201') &&
                        <div className='size-12 text-end'>
                          <Button size="small" type="link" onClick={() => navigate(`/member/info/${record.Id}`)}>
                            {i18n.t('detail')}
                          </Button>
                        </div>
                      }
                    </div>
                  </Space>
                )}
              >
                <Button size="small" className="size-12" type="link">{val}</Button>
              </Popover>
              <CopyButton text={val} />
            </Row>
          )
        },
        {
          dataIndex: 'RefCode',
          title: i18n.t('promotionCode'),
          width: 100,
          show: true,
        },
        {
          dataIndex: 'UPMemberAccount',
          title: i18n.t('recommendedMembers'),
          width: 120,
          show: true,
          render: (val: any) => val || '-'
        },
        {
          dataIndex: 'AGAccount',
          title: i18n.t('agent'),
          width: 120,
          show: true,
        },
        {
          dataIndex: 'IsFreeze',
          title: `${i18n.t('online')}／${i18n.t('status')}`,
          width: 120,
          show: true,
          render: (val: any, { IsOnline }: any) => (
            <Row align="middle">
              <Col>{icon.StatusTag(IsOnline)}</Col>
              <Col>{icon.MemberStateTag(val ? MEMBER_FREEZE.frozen : MEMBER_FREEZE.normal)}</Col>
            </Row>
          )
        },
        {
          dataIndex: 'Status',
          title: i18n.t('audit'),
          width: 100,
          show: true,
          render: (val: any) => <Row align="middle"><Col>{icon.MemberStateTag(val)}</Col></Row>
        },
        {
          dataIndex: 'MemberLevelName',
          title: i18n.t('memberLevel'),
          width: 100,
          show: true,
          render: (val: any, record: any) => data && data?.CommissionModule === '2' ? waterHover(record, val) : val
        },
        {
          dataIndex: 'MemberGroupName',
          title: i18n.t('memberGroup'),
          width: 100,
          show: true,
        },
        {
          dataIndex: 'CreateDate',
          title: `${i18n.t('createTime')}／IP`,
          width: 150,
          show: true,
          render: (val: any, { RegisterIP }: any) => (
            <Space direction="vertical">
              <div>{timeS2L(val)}</div>
              <div>{RegisterIP || '-'}</div>
            </Space>
          )
        },
        {
          dataIndex: 'LastLoginDate',
          title: `${i18n.t('lastLoginTime')}／IP`,
          width: 150,
          show: true,
          render: (val: any, { IP }: any) => (
            <Space direction="vertical">
              <div>{timeS2L(val)}</div>
              <div>{IP || '-'}</div>
            </Space>
          )
        },
        {
          dataIndex: 'LastDepositTime',
          title: i18n.t('lastDepositTime'),
          width: 150,
          show: true,
          render: (val: any, { LastDepositGatewayCode }: any) => (
            <Space direction="vertical">
              <div>{timeS2L(val)}</div>
              <div>{WALLET_TRANSLATE[LastDepositGatewayCode] || '-'}</div>
            </Space>
          )
        },
        {
          dataIndex: 'CommissionSettleCycle',
          title: i18n.t('rebate'),
          width: 100,
          show: true,
          render: (_: any, record: any) => waterHover(record, i18n.t(SETTLE_CYCLE[record.CommissionSettleCycle]))
        },
        {
          dataIndex: 'RemarkFromAccount',
          title: i18n.t('remark'),
          width: 150,
          show: true,
          // FIXME: memo抽取
          render: (comment: any, { Id, }: any) => (
            <Space>
              <Space direction="vertical">
                {
                  comment &&
                  comment.length > 20
                    ? <Tooltip placement="top" title={comment}>
                      <span>
                        {
                          comment.split('').map((text: string, i: number) => {
                            let outOf20 = '';
                            if (i < 20) {
                              outOf20 += text
                            }
                            return outOf20;
                          })
                        }
                        ...
                      </span>
                    </Tooltip>
                    : <span>{comment || '-'}</span>
                }
              </Space>
            </Space>
          )
        },
        {
          title: i18n.t('operation'),
          width: 150,
          fixed: 'right',
          show: true,
          render: (val: any, { Id, Account, AGId }: any) => (
            <>
              {
                (
                  $p('memberInformation.getMember') || 
                  $p('memberProviderSetting.getMemberProviderSettingList') || 
                  $p('memberContact.getMemberContactList') || 
                  $p('memberWithdrawMethohdList.getMemberWithdrawMethodList') || 
                  $p('memberOperatParams.getMemberOperatParams')
                ) &&
                <Button className="size-12" type="link" onClick={() => {
                  if ($p('memberInformation.getMember')) {
                    return navigate(`/member/info/${siteId}/${Id}/${Account}/${AGId}`)
                  } else if ($p('memberProviderSetting.getMemberProviderSettingList')) {
                    return navigate(`/member/provider/${siteId}/${Id}/${Account}/${AGId}`)
                  } else if ($p('memberContact.getMemberContactList')) {
                    return navigate(`/member/contact/${siteId}/${Id}/${Account}/${AGId}`)
                  } else if ($p('memberWithdrawMethohdList.getMemberWithdrawMethodList')) {
                    return navigate(`/member/bank/${siteId}/${Id}/${Account}/${AGId}`)
                  } else if ($p('memberOperatParams.getMemberOperatParams')) {
                    return navigate(`/member/rebate/${siteId}/${Id}/${Account}/${AGId}`)
                  }
                }}>
                  {i18n.t('view')}
                </Button>
              }
              {
                $p('accountDetail.getMemberAccountDetail') &&
                <Button className="size-12" type="link" onClick={() => navigate(`/member/account/${siteId}/${Account}`)}>
                  {i18n.t('accountRecords')}
                </Button>
              }
            </>
          )
        },
      ].map((item, i) => ({ ...item, key: i }))
        .filter(item =>{
          // 會員返水設定值吃會員vip等級則不顯示返水欄位
          return !(isRebateDependOnVipLevel && item.dataIndex === 'CommissionSettleCycle')}))
    }
  }, [init, siteId, data, isRebateDependOnVipLevel]);

  return (
    <>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          {/* 左邊 */}
          <Col>
            <Row gutter={[12, 12]}>
              {/* 匯出 */}
              {$p('member.exportMemberList') &&
              <Col>
                <Export
                  url={`admin/member/sites/${siteId}/members/export`}
                  param={{
                  ...searchParam, rows: data && data?.Total, page: 1
                  }}
                  sheetName={i18n.t('memberList')}
                  columns={ExcelColumns.MemberList.filter(item => {
                          if (isRebateDependOnVipLevel) {
                            return item.dataIndex !== 'CommissionSettleCycle';
                          } else {
                            return item;
                          }
                        })
                  }
                  disabled={!(siteId && triggerApi)}/>
              </Col>}
            </Row>
          </Col>
          {/* 右邊 */}
          <Col>
            <Row align="middle" gutter={[12, 12]}>
              <Col>
                <div className="color-03">{i18n.t('onlineMembers')}：{
                  toFormatNumber(OnlineMembers?.Data.Member, 0) || 0
                }</div>
              </Col>
              <Col>
                <Popover
                  placement="bottomRight"
                  trigger="click"
                  content={
                    <div className="w-10">
                      {columns.filter((item: any) => item.key !== 'Account' && item.key !== 'Operation')
                        .map((item: any) =>
                          <div key={item.key}>
                            <Checkbox
                              checked={item.show}
                              onChange={e => updateColumnShow(item.dataIndex, e.target.checked)}
                            >
                              {item.title}
                            </Checkbox>
                          </div>
                        )}
                    </div>
                  }
                >
                  <Button><DatabaseFilled /></Button>
                </Popover>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          loading={isValidating}
          size="small"
          scroll={{ x: 1500 }}
          dataSource={data?.Data?.map((item: any) => ({ ...item, key: item.Id }))}
          columns={columns.filter((item: any) => item.show) as any}
          pagination={false}
        />
      </Col>
    </>
  )
}

export const MemberGroupSelect = ({ name, form, mode, disabled, all = true, platformId }: {
  name: any;
  form: any
  mode?: any
  disabled?: boolean;
  all?: boolean;
  platformId?: number | null | undefined;
}) => {
  const { data } = $get({
    url: `admin/member/sites/${platformId}/member/groups`,
    params: {page: 1, rows: 9999},
    allow: !!platformId
  });

  const $s = (val: any) => val

  return (
    <Select
      value={$s(form.getFieldValue(name))}
      mode={mode}
      disabled={disabled}
      placeholder={i18n.t('memberGroup')}
      className="w-full"
      // options={
      //   all
      //     ? [{ value: '', label: i18n.t('ALL') },
      //       ...(data?.Data || []).map((item: any) => (
      //         { value: item.Id, label: item.Name }
      //       ))
      //     ]
      //     : [...(data?.Data || []).map((item: any) => (
      //       { value: item.Id, label: item.Name }
      //     ))
      //     ]
      // }
      options={
        all
          ? [{ value: '', label: i18n.t('ALL') },
            ...(data?.Data || []).map((item: any) => (
              { value: item.Id, label: item.Name }
            ))
          ].filter(item => !(mode === 'multiple' && item.value === ''))
          : [...(data?.Data || []).map((item: any) => (
            { value: item.Id, label: item.Name }
          ))
          ].filter(item => !(mode === 'multiple' && item.value === ''))
      }
      onChange={val => form.setFieldValue(name, $s(val))}
    />
  )
}