import Cookies from 'js-cookie';
import { COOKIE } from 'constants/cookie';
import { RESPONSE_CODE } from "constants/response";
import i18n from "i18n";
import { message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
	DecoupledEditor,
	Alignment,
	BalloonToolbar,
	Bold,
	FontBackgroundColor,
	FontColor,
	Heading,
	HorizontalLine,
	ImageBlock,
	ImageInsertViaUrl,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	List,
	RemoveFormat,
	Strikethrough,
	Table as CkTable,
	TableCellProperties,
	TableProperties,
	TableToolbar,
	TodoList,
	Underline,
	Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

export const CKEditorComp = ({ data, setData, form, fieldName, image, disabled }: any) => {

  const editorToolbarRef = useRef<any>(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    if (form) form.setFieldValue(fieldName, data);
  }, []);

  useEffect(() => {
    setIsLayoutReady(true);
    return () => setIsLayoutReady(false);
  }, []);

  class MyUploadAdapter {
    public loader: any;
    public token: any;

    constructor(loader: any) {
      this.loader = loader;
      this.token = Cookies.get(COOKIE.TOKEN);
    }

    upload() {
      return new Promise(async (resolve, reject) => {
        this.loader.file.then((file: any) => {
          this._sendRequest(resolve, reject, file);
        });
      });
    }

    _sendRequest(resolve: any, reject: any, file: any) {

      const newFormData = new FormData();
      newFormData.append('Media', file as any);

      const upload = fetch('admin/upload/images', {
        headers: {
          "Authorization": this.token
        },
        method: 'POST',
        body: newFormData,
      })
        .then(res => {
          const response: any = res.json();
          if (res.ok) return response;
          else throw response;
        })
        .then(data => {
          // 成功
          if (data.State === 'Success') {
            resolve({
              default: data.Data[0],
            });
          } else {
            const msg = RESPONSE_CODE[data.Message as keyof typeof RESPONSE_CODE];
            message.error(msg || i18n.t('processingFailed'));
            reject();
          };
        })

    }
  }

  function CustomImageUploadAdapter(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => new MyUploadAdapter(loader);
  };

  const editorConfig: any = {
    extraPlugins: [CustomImageUploadAdapter],
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'removeFormat',
        '|',
        'link',
        image ? 'imageUpload' : '',
        'insertTable',
        // '|',
        // 'horizontalLine',
        // 'alignment',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent'
      ],
      shouldNotGroupWhenFull: false
    },
    plugins: [
      Undo,
      Heading,
      FontColor,
      FontBackgroundColor,
      Bold,
      Italic,
      Underline,
      Strikethrough,
      RemoveFormat,
      Link,
      ImageUpload,
      HorizontalLine,
      Alignment,

      List,
      TodoList,
      ImageBlock,
      ImageInsertViaUrl,
      CkTable,
      TableToolbar,
      TableProperties,
      TableCellProperties,

      Indent,
      IndentBlock,

      BalloonToolbar,

      // Paragraph,
      // SimpleUploadAdapter,
      // AccessibilityHelp,
      // AutoImage,
      // AutoLink,
      // Autosave,
      // Code,
      // Essentials,
      // FontFamily,
      // FontSize,
      // ImageInsert,
      // ImageToolbar,
      // SelectAll,
      // SpecialCharacters,
      // TableCaption,
      // TableColumnResize,
    ],
    balloonToolbar: [
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'removeFormat'
    ],
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_paragraph'
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_heading1'
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_heading2'
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Heading 3',
          class: 'ck-heading_heading3'
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Heading 4',
          class: 'ck-heading_heading4'
        },
        {
          model: 'heading5',
          view: 'h5',
          title: 'Heading 5',
          class: 'ck-heading_heading5'
        },
        {
          model: 'heading6',
          view: 'h6',
          title: 'Heading 6',
          class: 'ck-heading_heading6'
        }
      ]
    },
    image: {
      toolbar: ['insertImage']
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      decorators: {
        toggleDownloadable: {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'file'
          }
        }
      }
    },
    menuBar: {
      isVisible: true
    },
    table: {
      contentToolbar: ['mergeTableCells', 'tableProperties', 'tableCellProperties']
    },
    // initialData: '<h2>Congratulations on setting up CKEditor 5!</h2>\n',
    // placeholder: 'Type or paste your content here!'
  };

  const oldConfig: any = {
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'link',
        image ? 'imageUpload' : '',
        'insertTable',
        'blockQuote',
        '|',
        'bulletedList',
        'numberedList',
      ]
    },
    image: {
      toolbar: [
        'insertImage',
        // 'imageStyle:full',
        // 'imageStyle:side',
        // '|',
        // 'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    language: 'en'
  }

  return (
    <div className="editor-container editor-container_document-editor">
      <div className="editor-container__toolbar" ref={editorToolbarRef}></div>
      {isLayoutReady && 
      <CKEditor
        disabled={disabled}
        data={data}
        onReady={editor => { editorToolbarRef.current.appendChild(editor.ui.view.toolbar.element) }}
        onAfterDestroy={() => { Array.from(editorToolbarRef.current.children).forEach((child: any) => child.remove()) }}
        onChange={(event, editor) => {
          const data = editor.getData();
        }}
        onBlur={(event, editor) => {
          const data = editor.getData();
          setData(data);
          if (form) form.setFieldValue(fieldName, data);
        }}
        config={editorConfig}
        editor={DecoupledEditor}
      />}
    </div>
    // 舊版
    // <CKEditor
    //   disabled={disabled}
    //   data={data}
    //   onChange={(event, editor) => {
    //     const data = editor.getData();
    //   }}
    //   onBlur={(event, editor) => {
    //     const data = editor.getData();
    //     setData(data);
    //     if (form) form.setFieldValue(fieldName, data);
    //   }}
    //   config={{
    //     extraPlugins: [CustomImageUploadAdapter],
    //     toolbar: CKdefaultConfig.toolbar,
    //   }}
    //   editor={ClassicEditor}
    // />
  )
}