import { Content } from 'antd/es/layout/layout';
import { DatePickerUnix, Export, LayoutNav } from "components/layout.component";
import React, { useEffect, useState } from "react";
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Button, Checkbox, Col, Form, Popover, Row, Spin, Table, Tooltip, Empty, Card } from "antd";
import i18n from "../i18n";
import { SiteCodeAndName } from "../components/site.component";
import { toFormatNumber2 as $f, timeL2S, numColor01, numColor03 } from "utils/common";
import { DATE_TYPE } from "../constants/date";
import { DatabaseFilled, InfoCircleFilled } from "@ant-design/icons";
import { Bar, Line } from 'react-chartjs-2';
import useAccount from "../hooks/account.hook";
import dayjs from "dayjs";
import { $get } from "../services";
import { ExcelColumns } from 'constants/excel';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartTooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from 'chart.js';

// 圖表函式庫
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend,
  PointElement,
  LineElement,
);

// 營運報表

const chartOptions = {
  responsive: true,
  scales: {
    y: {
      ticks: {
        stepSize: 1
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
  },
}

const PageMain: React.FC = () => {
  const { init, permissions: $p } = useAccount();

  const [form] = Form.useForm();
  const [reset, setReset] = useState<boolean>(false);
  const [isOpenMore, setIsOpenMore] = useState(false);
  const [siteId, setSiteId] = useState<number | null>();

  const [date, setDate] = useState([
    dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss')
  ]);

  const [reportBusinessParam, SetReportBusinessParam] = useState<ReportBusinessParam>({
    startDate: timeL2S(date[0] as string),
    endDate: timeL2S(date[1] as string),
  });
  const { data: reportBusiness, mutate, isValidating } = $get({
    url: `admin/operation-report/sites/${siteId}/reports`,
    params: reportBusinessParam,
    allow: !!siteId
  });

  const [amountData, setAmountData] = useState<any>({ datasets: [] });
  const [newcomerData, setNewcomerData] = useState<any>({ datasets: [] });
  const [betData, setBetData] = useState<any>({ datasets: [] });
  const [profitData, setProfitData] = useState<any>({ datasets: [] });

  useEffect(() => {
    if (reportBusiness) {
      const res = [...reportBusiness?.Data].reverse() || [];
      const labels = res.map((item: any) => item.ReportDate);
      setAmountData({
        labels,
        datasets: [
          {
            label: i18n.t('totalDepositAmount'),
            data: res.map((item: any) => item.TotalDepositAmount || 0),
            backgroundColor: '#AFC8EA',
          },
          {
            label: i18n.t('totalAmountWithdrawal'),
            data: res.map((item: any) => item.TotalWithdrawAmount || 0),
            backgroundColor: '#CBEAD9',
          },
        ],
      });
      setNewcomerData({
        labels,
        datasets: [
          {
            label: i18n.t('newUsers'),
            data: res.map((item: any) => item.TotalNewMemberCount || 0),
            backgroundColor: '#AFC8EA',
          },
          {
            label: i18n.t('activeMembers'),
            data: res.map((item: any) => item.TotalActivityMemberCount || 0),
            backgroundColor: '#CBEAD9',
          },
          {
            label: i18n.t('bettingMembers'),
            data: res.map((item: any) => item.TotalActivityMemberCount || 0),
            backgroundColor: '#E79F9C',
          },
        ],
      });
      setBetData({
        labels,
        datasets: [
          {
            label: i18n.t('betAmountValid'),
            data: res.map((item: any) => item.TotalValidBetAmount || 0),
            backgroundColor: '#AFC8EA',
          },
          {
            label: i18n.t('totalBetMoney'),
            data: res.map((item: any) => item.TotalBetAmount || 0),
            backgroundColor: '#CBEAD9',
          },
        ],
      });
      setProfitData({
        labels,
        datasets: [
          {
            label: i18n.t('gameProfit'),
            data: res.map((item: any) => item.TotalWinLossAmount || 0),
            borderColor: '#4874AD',
            backgroundColor: '#4874AD',
          },
        ],
      });
    };
  }, [reportBusiness]);

  // 搜尋
  const search = () => {
    setSiteId(siteId);
    SetReportBusinessParam({
      startDate: timeL2S(date[0] as string),
      endDate: timeL2S(date[1] as string)
    })
    mutate();
  };

  const siteCodeChange = (id: number) => {
    onClear();
    form.setFieldsValue({ siteId: id });
    setTimeout(() => {
      setSiteId(id);
    });
  }

  const onClear = () => {
    setReset(!reset);
  }

  const handleMore = (e: CheckboxChangeEvent) => {
    setIsOpenMore(e.target.checked)
  }

  const sum = (prop: any) => {
    return reportBusiness?.Data?.reduce((a: any, b: any) => b[prop] ? a + b[prop] : a, 0);
  };

  const [columns, setColumns] = useState<any>([]);

  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.children[0].dataIndex === key) {
        item.show = checked

        return true
      }
    })
    setColumns([...columns]);
  };

  useEffect(() => {
    if (init && reportBusiness) {
      setColumns([
        {
          title: i18n.t('date'),
          align: 'center',
          show: true,
          children: [
            {
              dataIndex: 'ReportDate',
              title: i18n.t('total'),
              align: 'right',
              fixed: 'left',
              width: 120,
              render: (val: any) => <div className="text-center">{val}</div>
            },
          ]
        },
        {
          title: i18n.t('newUsers'),
          show: true,
          children: [
            {
              dataIndex: 'TotalNewMemberCount',
              title: $f(sum('TotalNewMemberCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('activeMembers'),
          show: true,
          children: [
            {
              dataIndex: 'TotalActivityMemberCount',
              title: $f(sum('TotalActivityMemberCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('totalMemberCount'),
          show: true,
          children: [
            {
              dataIndex: 'TotalDepositRecordCount',
              title: $f(sum('TotalDepositRecordCount'), 0),
              align: 'right',
              width: 100,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('totalWithdrawalCount'),
          show: true,
          children: [
            {
              dataIndex: 'TotalWithdrawRecordCount',
              title: $f(sum('TotalWithdrawRecordCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('firstDepositMembers'),
          show: true,
          children: [
            {
              dataIndex: 'TotalFirstTimeDepositMemberCount',
              title: $f(sum('TotalFirstTimeDepositMemberCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('redepositMembers'),
          show: true,
          children: [
            {
              dataIndex: 'TotalSecondTimeDepositRecordCount',
              title: $f(sum('TotalSecondTimeDepositRecordCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('firstDepositAmount'),
          show: true,
          children: [
            {
              dataIndex: 'TotalFirstTimeDepositAmount',
              title: $f(sum('TotalFirstTimeDepositAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ],
        },
        {
          title: i18n.t('totalDepositAmount'),
          show: true,
          children: [
            {
              dataIndex: 'TotalDepositAmount',
              title: $f(sum('TotalDepositAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ]
        },
        {
          title: i18n.t('totalAmountWithdrawal'),
          show: true,
          children: [
            {
              dataIndex: 'TotalWithdrawAmount',
              title: $f(sum('TotalWithdrawAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ]
        },
        {
          title: () => (
            <>
              <Tooltip placement="top" className="size-12"
                       title={i18n.t(`dailyDataWithoutDuplicateAccounts`)}>
                <InfoCircleFilled />
              </Tooltip>
              <span style={{ marginLeft: 5 }}>{i18n.t('bettingMembers')}</span>
            </>
          ),
          show: true,
          children: [
            {
              dataIndex: 'TotalBettingMemberCount',
              title: $f(sum('TotalActivityMemberCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any, record: any) => $f(record.TotalActivityMemberCount, 0)
            }
          ]
        },
        {
          title: i18n.t('totalBets'),
          show: true,
          children: [
            {
              dataIndex: 'TotalBetCount',
              title: $f(sum('TotalBetCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('betAmountValid'),
          show: true,
          children: [
            {
              dataIndex: 'TotalValidBetAmount',
              title: $f(sum('TotalValidBetAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ]
        },
        {
          title: i18n.t('payout'),
          show: true,
          children: [
            {
              dataIndex: 'TotalSettleAmount',
              title: $f(sum('TotalSettleAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ]
        },
        {
          title: i18n.t('gameProfit'),
          show: true,
          children: [
            {
              dataIndex: 'TotalWinLossAmount',
              title: $f(sum('TotalWinLossAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => <span className={numColor03(val)}>{$f(val)}</span>
            }
          ]
        },
        {
          title: i18n.t('monthlyTotalWinLoss'),
          show: true,
          children: [
            {
              dataIndex: 'TotalMonthWinLossAmount',
              title: '-',
              align: 'right',
              width: 120,
              render: (val: any) => <span className={numColor01(val)}>{$f(val)}</span>
            }
          ]
        },
        {
          isOpenMore: true,
          title: i18n.t('promotionAmount'),
          show: true,
          children: [
            {
              dataIndex: 'TotalBonusAmount',
              title: $f(sum('TotalBonusAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ],
        },
        {
          isOpenMore: true,
          title: i18n.t('adjustmentAmount'),
          show: true,
          children: [
            {
              align: 'right',
              width: 120,
              title: () => {
                const adjustTotal = Math.abs(sum('TotalAdjustMinusAmount'));
                return (
                  <>
                    <div className={numColor03(sum('TotalAdjustPlusAmount'))}>
                      {$f(sum('TotalAdjustPlusAmount'))}
                    </div>
                    <div className={numColor03(-adjustTotal)}>
                      {adjustTotal !== 0 ? $f(-adjustTotal) : $f(0)}
                    </div>
                  </>
                )
              },
              render: (val: any, { TotalAdjustPlusAmount, TotalAdjustMinusAmount }: any) => {
                const adjustTotal = Math.abs(TotalAdjustMinusAmount);
                return (
                  <div>
                    <div className={numColor03(TotalAdjustPlusAmount)}>{$f(TotalAdjustPlusAmount)}</div>
                    <div className={numColor03(-adjustTotal)}>
                      {TotalAdjustMinusAmount !== 0 ? $f(-adjustTotal) : $f(0)}
                    </div>
                  </div>
                )
              }
            }
          ]
        }
      ])
    }
  }, [init && reportBusiness]);

  return (
    <div id="container">
      <Content className="p-2">
        {/* 搜尋 */}
        <Form form={form} onFinish={search}>
          {/* 搜尋列 */}
          <Row align="top" gutter={12}>
            <Col className="w-12">
              <Form.Item name="siteId" rules={[{ required: true, message: `${i18n.t('pleaseSelect')}${i18n.t('platform')}` }]}>
                <SiteCodeAndName name="siteId" form={form} apiUrl={'admin/operation-report/sites'} handleChangeFunction={siteCodeChange} />
              </Form.Item>
            </Col>
            <DatePickerUnix width={190} date={date} setDate={setDate} initDate={reset} defaultDateType={DATE_TYPE.thisMonth}/>
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 圖表 */}
        <Spin spinning={isValidating}>
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={12}>
              <Col className="size-16">{i18n.t('financeStatistics')}</Col>
              {amountData.datasets.length !== 0 ? <Bar options={chartOptions} data={amountData} /> : <Card style={{marginTop: 8}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></Card>}
            </Col>
            <Col span={12}>
              <Col className="size-16">{i18n.t('memberActivityStatistics')}</Col>
              {newcomerData.datasets.length !== 0 ? <Bar options={chartOptions} data={newcomerData} /> : <Card style={{marginTop: 8}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></Card>}
            </Col>
          </Row>
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={12}>
              <Col className="size-16">{i18n.t('betStatistics')}</Col>
              {betData.datasets.length !== 0 ? <Bar options={chartOptions} data={betData} /> : <Card style={{marginTop: 8}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></Card>}
            </Col>
            <Col span={12}>
              <Col className="size-16">{i18n.t('gameProfit')}</Col>
              {profitData.datasets.length !== 0 ? <Line options={chartOptions} data={profitData} /> : <Card style={{marginTop: 8}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></Card>}
            </Col>
          </Row>
        </Spin>
        {/* 列表 */}
        <Row className="mt-1" gutter={[10, 10]}>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                {$p('operationReport.exportOperationReport') && <Export url={`admin/operation-report/sites/${siteId}/reports/export`} param={{ ...reportBusinessParam }}
                  sheetName={i18n.t('operationReports')} columns={ExcelColumns.ReportBusiness} />}
              </Col>
              <Col>
                <Row gutter={[5, 5]}>
                  <Col>
                    <Button onClick={() => setIsOpenMore(!isOpenMore)}>
                      <Checkbox style={{ marginRight: 5 }} defaultChecked={false} checked={isOpenMore} onChange={handleMore} />
                      {i18n.t('More')}
                    </Button>
                  </Col>
                  <Col>
                    <Popover
                      placement="bottomRight"
                      trigger="click"
                      content={
                        <div className="w-full">
                          {columns
                            .filter((item: any) => !item.isOpenMore)
                            .map((item: any) =>
                              <div key={item.key}>
                                <Checkbox
                                  checked={item.show}
                                  onChange={e => {
                                    updateColumnShow(item.children[0].dataIndex, e.target.checked)
                                  }}
                                >
                                  {typeof item.title === 'function' ? (
                                    React.Children.map(item.title().props.children[1], (child) =>
                                      typeof child === 'object' && child.type === 'span' ? (
                                        child.props.children
                                      ) : (
                                        child
                                      )
                                    )
                                  ) : (
                                    item.title
                                  )}
                                </Checkbox>
                              </div>
                            )}
                        </div>
                      }
                    >
                      <Button><DatabaseFilled /></Button>
                    </Popover>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: '60px' }}>
            <Table
              loading={isValidating}
              size="middle"
              bordered
              scroll={{ y: 600, x: '100%' }}
              dataSource={reportBusiness?.Data?.map((item: ReportBusiness) => ({ ...item, key: item.ReportDate }))}
              columns={columns
                .filter((item: any) => item.show)
                .filter((item: any) => !(!isOpenMore && item.isOpenMore)) as any}
              pagination={false}
            />
          </Col>
        </Row>
      </Content>
    </div >
  );
};

export default PageMain;